import React, { Component, FormEvent } from 'react';
import { Link } from 'react-router-dom';
import { client } from '../../core/graphql';
import { CURRENT_USER } from '../../core/graphql/quering';
import { Class, ClassConnection, Classroom, ClassroomConnection, Department, DepartmentConnection, Profesor, ProfesorConnection, Student, StudentConnection, Subject, SubjectConnection } from '../../core/models/interfaces';
import { Modal } from '../modals/Modal';
import { SelectModal } from '../modals/SelectModal';
import { ALL_ENTITIES } from './queries';
import is from 'is_js';
// import { ROLES } from '../../core/graphql/quering/queries/users';
//import {useForm} from 'react-hook-form';

interface Field {
  target?: string;
  name: string;
  value: any;
  type: string;
  label: string;
  width?: string | number;
  rows?: number;
  notEditable?: boolean;
  options?: any;
  className?: string;
  valueString?: string;
  required?: boolean;
  allow?: string;
  invalid?: boolean;
  message?: string;
  onChange?: Function;
}

interface IProps {
  id?: string;
  roles?: any;
  modalTarget?: string;
  navigationBase?: string;
  entityId?: string | number;
  readonly?: boolean;
  fields: Field[];
  title: string;
  subtitle?: string;
  submitLabel?: string;
  resetLabel?: string;
  onChange?: Function;
  validate?: Function;
  onSubmit?: Function;
  header?: boolean;
  viewMoreLabel?: string;
  changeLabel?: string;
  errors?: { [field: string]: string[] }
}

interface IState {
  fields: Field[];
  errors?: { [field: string]: string[] };
  // temp?: Field[];
  canEdit?: boolean;
  rolesBD?: any;
  professors?: Profesor[];
  classes?: Class[];
  students?: Student[];
  subjects?: Subject[];
  classrooms?: Classroom[];
  departments?: Department[];

  selectedModal?: string;
}

export class UserForm extends Component<IProps, IState> {

  initial: any;
  mounted: boolean;
  constructor(props: IProps) {
    super(props);
    this.state = {
      fields: props.fields ? this.props.fields.map(f => ({ ...f })) : undefined,
      errors: props.errors
    };
    this.initial = this.props.fields;
  }

  componentDidMount() {
    this.mounted = true;
    this.fetchEntities();
    const dates = document.querySelectorAll('.datepicker');
    const auth = client.readQuery({ query: CURRENT_USER });
    const canEdit = auth?.currentUser?.role?.name === 'ROLE_ADMIN';
    this.setState({ canEdit });
    const that = this;
    dates.forEach((date: HTMLInputElement) => {
      const picker = M.Datepicker.init(date, {
        format: 'yyyy-mm-dd',
        onSelect: (value) => {
          const formatter = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });
          const [{ value: month }, , { value: day }, , { value: year }] = formatter.formatToParts(value);
          that.onFieldChange(`${year}-${month}-${day}`, date.name);
        }
      });
      picker.gotoDate(new Date(this.state.fields.find(f => f.name === date.name)?.value))
    })
    M.FormSelect.init(document.querySelectorAll('select'), { classes: 'pagination-select text-primary border-bottom' });

    // const ROLE_EXIST = {
    //   "1": "ROLE_ADMIN",
    //   "2": "ROLE_STUDENT",
    //   "3": "ROLE_PROFESOR",
    // };

    // this.setState({
    //   ...this.state,
    //   rolesBD: ROLE_EXIST
    // })

  }

  onSubmit(e: FormEvent) {
    e.preventDefault();
    // this.setState({ temp: this.state.fields })

    if (this.props.onSubmit) {
      this.props.onSubmit(this.state.fields);
      this.setState({
        ...this.state,
        fields: this.props.fields,
        // temp: [],
        errors: {}
      },);
    }
  }

  onReset() {
    this.setState({
      ...this.state,
      fields: this.initial,
      // temp: [],
    });
  }

  onFieldChange(value, name) {

    const fields = [...this.state.fields?.map(f => ({ ...f }))];
 
    const field = fields.find(f => f.name === name);
 
    if (field) {
      field.value = value;
      
      this.setState({
        ...this.state,
        fields,
        // temp: fields,
      },);
    }
  }

  onInput(e: React.FormEvent<HTMLInputElement>, allow: string) {
    const event: InputEvent = e.nativeEvent as any;
    if (allow === 'numbers' && !(/^\d+$/.test(event?.data))) {
      e.preventDefault();
      e.stopPropagation();
      event.preventDefault();
      event.stopPropagation()
    }
    // TODO HACER EL REVENT DEFAULT
  }

  onModalSelect(id, label) {
    // alert(`${id} ${label}`)
    const fields = [...this.state.fields];
    const field = fields.find(f => f.name === this.state.selectedModal);

    if (field) {
      field.value = id;
      field.valueString = label;
      this.setState({
        ...this.state,
        fields,
        // temp: fields
      });
    }
  }

  UNSAFE_componentWillReceiveProps(props: IProps) {
    this.initial = [...props.fields];

    this.setState({
      ...this.state,
      fields: props.fields,
      errors: props.errors

    })

  }

  parseInputType(field: Field, key) {
    // const regexp = new RegExp('/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/');
    let hasError = !!(this.props?.errors && this.props?.errors[field.name] && this.props?.errors[field.name].length);
    const hasField = hasError ? this.state?.errors[field.name] : undefined;
    let error = '';
    if (hasError && !!hasField?.length) {
      error = hasField[0];
    }
    if (!!this.state.errors && this.state.errors[field?.name]?.length) {
      error = this.state.errors[field.name][0];
      hasError = true;
    }
    if (field.type === 'modal') {
      return (
        <div className={`col-md-${field.width || 4}`} key={key}>
          <div className="form-group bmd-form-group w-100 position-relative">
            <input type={field.type || 'text'} className={`form-control`} value={field.valueString || ''} onChange={(e) => { }} readOnly={this.props.readonly || field.notEditable} />
            <label className="bmd-label-floating">{field.label} {field.required && ' *'}</label>
            <a href="#!" className="position-absolute" style={{ top: '0', left: '0', width: '100%', height: '100%' }}
              data-toggle="modal" data-target={`#${field.options}`} onClick={(ev) => { ev.preventDefault(); this.setState({ ...this.state, selectedModal: field.name }) }}> </a>
          </div>
        </div>
      )
    } else if (!field.type || (field.type !== 'select' && field.type !== 'textarea' && field.type !== 'radio' && field.type !== 'checkbox' && field.type !== 'date' && field.type !== 'email')) {
      return (
        <div className={`col-md-${field.width || 4} d-flex align-items-bottom`} key={key}>
          <div className={`form-group bmd-form-group ${field.className || ''} w-100 ${hasError ? 'has-danger' : ''}`}>
            <input type={field.type || 'text'} className={`form-control`} value={field.value || (field.type === 'number' ? '0' : '')}
              // onInput={(e) => this.onInput(e, field.allow)}
              onChange={(e) => {
                // console.log(e.target.value);
                if (field.allow === 'numbers' && (e.nativeEvent as any)?.inputType === 'insertText' && !(/\d/.test((e?.nativeEvent as any)?.data))) {
                  e.preventDefault();
                } else {
                  this.onFieldChange(field.type === 'number' ? e.target.valueAsNumber : e.target.value, field.name)
                  if (field.onChange) {
                    field.onChange(e)
                      .then((data) => {
                        if (!data.unique) {
                          this.setState({
                            ...this.state,
                            errors: {
                              ...this.state.errors,
                              [field.name]: ['El campo debe tener un valor unico']
                            }
                          })
                        } else {
                          this.setState({
                            ...this.state,
                            errors: {
                              ...this.state.errors,
                              [field.name]: undefined
                            }
                          })
                        }
                      });
                  }
                }
              }}
              readOnly={this.props.readonly || field.notEditable} />
            <label className="bmd-label-floating">{field.label} {field.required && ' *'}</label>
            {error && <small className="form-text text-muted text-danger">{error}</small>}
          </div>
        </div>
      );
    } else if (field.type === 'textarea') {
      return (
        <div className={`col-md-${field.width || 4}`} key={key}>
          <div className="form-group bmd-form-group w-100">
            <textarea className="form-control" rows={field.rows || 2} value={field.value || ''}
              onChange={(e) => this.onFieldChange(e.target.value, field.name)} readOnly={this.props.readonly || field.notEditable}></textarea>
            <label className="bmd-label-floating">{field.label} {field.required && ' *'}</label>
            {error && <small className="form-text text-muted text-danger">{error}</small>}
          </div>
        </div>
      )
    } else if (field.type === 'email') {
      return (
        <div className={`col-md-${field.width || 4} d-flex align-items-bottom`} key={key}>
          <div className={`form-group bmd-form-group ${field.className || ''} w-100 ${hasError ? 'has-danger' : ''}`}>
            <input type="email"
              readOnly={this.props.readonly || field.notEditable}
              className={`form-control`}
              value={field.value || ''}
              //  pattern="/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3,4})+$/"
              onChange={
                (e) => {
                  this.onFieldChange(e.target.value, field.name);
                  this.setState({
                    errors: {
                      "email": undefined
                    }
                  })
                  if (is.not.email(e.target.value) && e.target.value !== '') {
                    this.onFieldChange(e.target.value, field.name);
                    this.setState({
                      errors: {
                        "email": ["El email es invalido"]
                      }
                    })
                  }
                }
              }
            //  ref= {
            //    this.props.validate({
            //      required: "Por favor inserte su email",
            //      pattern: {
            //        value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3,4})+$/,
            //        message: "Su direccion de email es invalida, por favor verifiquela"
            //      }
            //    })
            //  }
            />
            <label className="bmd-label-floating">{field.label} {field.required && ' *'}</label>
            {error && <small className="form-text text-muted text-danger">{error}</small>}
          </div>
        </div>
      )
    }
    else if (field.type === 'date') {
      return (
        <div className={`col-md-${field.width || 4}`} key={key}>
          <div className="form-group bmd-form-group w-100">
            {/* <input type="text" value={field.value || ''} onChange={(ev) => {
                 console.log(ev.target.value)
                }} name={field.name} className="form-control datepicker" />
            */}
            <input type="date" readOnly={this.props.readonly || field.notEditable} className="form-control" value={field.value || ''} onChange={(ev) => { this.onFieldChange(ev.target.value, field.name) }} />
            <label className="bmd-label-floating">{field.label} {field.required && ' *'}</label>
            {error && <small className="form-text text-muted text-danger">{error}</small>}
          </div>
        </div>
      );
    } else if (field.type === 'checkbox') {
      return (
        <div className={`col-md-${field.width || 4} d-flex align-items-center`} key={key}>
          <label>
            <input type="checkbox" onChange={(e) => this.onFieldChange(e.target.checked, field.name)} checked={!!field.value}
              disabled={this.props.readonly || field.notEditable} />
            <span>{field.label} {field.required && ' *'}</span>
          </label>
        </div>
      )
    } else if (field.type === 'select') {

      let options = [];

      for (const e in field.options) {
        options.push((
          <option key={e} value={e}>{field.options[e]}</option>
        ))
      }

      return (
        <div className={`col-md-${field.width || 4}`} key={key}>
          <div className="input-field" style={{ marginTop: '7px' }}>
            <select defaultValue={field.value || -1} onChange={(e) => field.name === 'academyGrade' ? this.onFieldChange(parseInt(e.target.value), field.name) : this.onFieldChange(e.target.value, field.name)}
              disabled={this.props.readonly || field.notEditable} >
              <option disabled value={-1}>Seleccione</option>
              {options}
            </select>
            <label>{field.label} {field.required && ' *'}</label>
            {error && <small className="form-text text-muted text-danger">{error}</small>}
          </div>
        </div>
      )
    }
  }

  fetchEntities() {
    client.query<{
      classroomsConnection: ClassroomConnection,
      departmentsConnection: DepartmentConnection,
      profesorsConnection: ProfesorConnection,
      studentsConnection: StudentConnection,
      subjectsConnection: SubjectConnection,
      classesConnection: ClassConnection
    }>({ query: ALL_ENTITIES })
      .then(res => {
        const {
          classroomsConnection: { values: classrooms },
          profesorsConnection: { values: professors },
          studentsConnection: { values: students },
          departmentsConnection: { values: departments },
          subjectsConnection: { values: subjects },
          classesConnection: { values: classes }
        } = res.data;

        this.setState({
          ...this.state,
          classrooms,
          professors,
          students,
          subjects,
          departments,
          classes
        });
      }).catch(err => console.error(err))
  }

  // fetchEntities() {
  //   client.query<{
  //     classroomsConnection: ClassroomConnection,
  //     departmentsConnection: DepartmentConnection,
  //     profesorsConnection: ProfesorConnection,
  //     studentsConnection: StudentConnection,
  //     subjectsConnection: SubjectConnection,
  //     classesConnection: ClassConnection
  //   }>({ query: ALL_ENTITIES })
  //     .then(res => {
  //       const {
  //         classroomsConnection: { values: classrooms },
  //         profesorsConnection: { values: professors },
  //         studentsConnection: { values: students },
  //         departmentsConnection: { values: departments },
  //         subjectsConnection: { values: subjects },
  //         classesConnection: { values: classes }
  //       } = res.data;

  //       this.setState({
  //         ...this.state,
  //         classrooms,
  //         professors,
  //         students,
  //         subjects,
  //         departments,
  //         classes
  //       });
  //     }).catch(err => console.error(err))
  // }

  render() {

    const valid = this.state?.fields?.reduce((v, f) => f.required ? v && !!f.value : v, true) && this.state?.errors?.["email"] === undefined;
    
    return (
      <>
        <div className="card">
          {this.props.header && <div className="card-header card-header-primary">
            <h4 className="card-title">{this.props.title}</h4>
            <p className="card-category">{this.props.subtitle}</p>
          </div>}
          <div className="card-body mt-3">
            <form onSubmit={(e) => this.onSubmit(e)} onReset={() => this.onReset()}>
              <div className="row">
                {/* this.state.temp ? this.state.temp[key] || field :  */}
                {this.state?.fields?.map((field, key) => this.parseInputType(field, key))}
              </div>

              <p className="">(*) Campo obligatorio.</p>
              {!this.props.readonly && !this.props.modalTarget && <button disabled={!valid} type="submit" className="btn btn-primary pull-right">{this.props.submitLabel || 'Enviar'}</button>}
              {!this.props.readonly && !this.props.modalTarget && <button type="reset" className="btn grey pull-right mx-3">{this.props.resetLabel || 'Restablecer'}</button>}
              {!this.props.navigationBase && !!this.props.entityId && <Link className="btn btn-info" to={`${this.props.navigationBase}/${this.props.entityId}`}>{this.props.viewMoreLabel || 'Detalles'}</Link>}
              {!!this.props.modalTarget && !this.props.readonly && <button type="button" className="btn btn-primary" data-toggle="modal" data-target={`#${this.props.modalTarget}`}>
                {this.props.changeLabel || 'Cambiar'}
              </button>}
              <div className="clearfix"></div>
            </form>
          </div>
        </div>

        <Modal content={<SelectModal
          id={this.props.id || "relationClassroom"}
          title="Seleccione el aula"
          placeholder="Inserte el aula"
          options={this.state.classrooms?.map(p => ({ value: p.id, label: `(${p.id}) ${p.address}` }))}
          onConfirm={(id, label) => { this.onModalSelect(id, label) }} />} />

        <Modal content={<SelectModal
          id={this.props.id || "relationProfesor"}
          title="Seleccione el profesor"
          placeholder="Inserte el profesor"
          options={this.state.professors?.map(p => ({ value: p.id, label: `(${p.id}) ${p.name} ${p.lastname}` }))}
          onConfirm={(id, label) => { this.onModalSelect(id, label) }} />} />

        <Modal content={<SelectModal
          id={this.props.id || "relationSubject"}
          title="Seleccione la asignatura"
          placeholder="Inserte la asignatura"
          options={this.state.subjects?.map(p => ({ value: p.id, label: `(${p.id}) ${p.name}` }))}
          onConfirm={(id, label) => { this.onModalSelect(id, label) }} />} />

        <Modal content={<SelectModal
          multiple={true}
          id={this.props.id || "relationClass"}
          title="Seleccione las clases"
          options={this.state.classes?.map(p => ({ value: p.id, label: `(${p.id}) ${p.asignatura?.name}` }))}
          onConfirm={(id, label) => { this.onModalSelect(id, label) }} />} />

        <Modal content={<SelectModal
          multiple={true}
          id={this.props.id || "relationDepartment"}
          title="Seleccione los departamentos"
          options={this.state.departments?.map(p => ({ value: p.id, label: `(${p.id}) ${p.name}` }))}
          onConfirm={(id, label) => { this.onModalSelect(id, label) }} />} />
      </>
    );
  }
}