import React from 'react';
import ReactDOM from 'react-dom';
import { Component } from 'react';

interface IProps {
  content: any;
}

export class Modal extends Component<IProps> {

  render() {
    return ReactDOM.createPortal((<>{this.props.content}</>), document.querySelector('#modals'))
  }
}