import { gql } from "@apollo/client";

export const DASHBOARD_QUERY = gql`
query DashboardQuery {
	studentsConnection {
    values {
      id,
      califications
			currentLevel
			active
    }
	aggregate { count }
  }

	classesConnection {
		values {
			state,
			startDate,
			finishDate,
			asignatura { name }
		}
    aggregate { count }
  }

	profesorsConnection(where: {active: true }) {
		values { active }
    aggregate { count }
  }
}`;