import React, { Component, createRef } from 'react';

// declare module 'react' {
//   interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
//     // extends React's HTMLAttributes
//     tabindex?: string;
//   }
// }

interface IProps {
  title?: string;
  cancelLabel?: string;
  confirmLabel?: string;
  content?: string;
  id: string;
  onConfirm?: Function
}

export class ConfirmModal extends Component<IProps> {

  showBtn = createRef<HTMLButtonElement>()
  show() {
    this.showBtn.current.click();
  }

  render() {
    return (
      <>
      <div className="modal fade" id={this.props.id} tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{ this.props.title || 'Title' }</h5>
              <button type="button" className="close transparent" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              { this.props.content }
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">{ this.props.cancelLabel || 'Cancelar'}</button>
              <button type="button" onClick={() => {this.props.onConfirm && this.props.onConfirm()}} className="btn btn-primary" data-dismiss="modal">{ this.props.confirmLabel || 'Confirmar' }</button>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}