import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import { useQuery } from '@apollo/client';
import { DASHBOARD_QUERY } from './dashboard-queries';
import { ClassConnection, ProfesorConnection, Student, StudentConnection } from '../../../../core/models/interfaces';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/es';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Dashboard.css';
// import Testimonials from './Testimonials';
// import { client } from '../../../../core/graphql';
// import { STUDENT, STUDENTS, STUDENTS_FILTER } from '../../../../core/graphql/quering/queries/students';

function getTotalCredits(student: Student) {
	let credits = 0;
	Object.keys(student?.califications || {}).forEach(key => {
		const c = student.califications[key].credit;
		credits += c;
	})
	return credits;
}

interface IState {
	events: { start: Date, end: Date, title: string }[];
	students: number;
	professors: number;
	classes: number;
}

export function Dashboard() {

	// QUERIES

	const [state, setState] = useState<IState>({ events: [], professors: 0, students: 0, classes: 0 })
	const { data: dashboard, error, loading } = useQuery<{ studentsConnection: StudentConnection, classesConnection: ClassConnection, profesorsConnection: ProfesorConnection }>(DASHBOARD_QUERY);
	// const { data, error: E, loading: L} = useQuery(STUDENT, {onCompleted: (data)=>{console.log(data)},  variables: {limit:50, start:0}});
	const chart1 = useRef<HTMLCanvasElement>();
	const chart2 = useRef<HTMLCanvasElement>();
	const chart3 = useRef<HTMLCanvasElement>();

	moment.locale('es')
	const localizer = momentLocalizer(moment) // or globalizeLocalizer

	useEffect(() => {
		// Estudiantes graduados
		if (!error && !loading) {
			const studentsPerLevel: { [level: string]: number } = {}
			const studentCredits = {
				low: {
					label: 'Menos de 70',
					count: 0,
				},
				middle: {
					label: 'Más de 70 y menos de 126',
					count: 0
				},
				hight: {
					label: 'Más de 126',
					count: 0
				}
			}

			let students = 0;
			let professors = 0;
			let classes = 0;
			// let username = "Alex";
			// let lastName = "Zaldivar";
			// let append = lastName.split(' ');
			// append.map(a => username = username.concat(a.slice(0, 1)));
			// console.log(username);
			
			dashboard.studentsConnection.values?.forEach(student => {

				studentsPerLevel[student.currentLevel] = (studentsPerLevel[student.currentLevel] || 0) + 1
				const credits = getTotalCredits(student);
				if (credits < 70) {
					studentCredits.low.count++;
				} else if (credits < 126) {
					studentCredits.middle.count++;
				} else {
					studentCredits.hight.count++;
				}
			});

			const events = dashboard.classesConnection?.values?.map(c => {
				const start = new Date(c.startDate);
				const finish = new Date(c.finishDate);

				start.setDate(start.getDate() + 1);
				finish.setDate(finish.getDate() + 1);

				return ({ start: start, end: finish, title: c.asignatura?.name });
			}
			)

			const currentYear = +moment(Date.now()).format('YYYY');
			const classesPerYears = {};

			dashboard?.classesConnection?.values?.forEach(c => {
				const year = +moment(c.finishDate).format('YYYY');

				if (year > currentYear - 5) {
					classesPerYears[year] = (classesPerYears[year] || 0) + 1;
				}
			})
			classes = dashboard?.classesConnection?.aggregate.count
			professors = dashboard?.profesorsConnection?.aggregate.count
			students = dashboard?.studentsConnection?.aggregate.count

			setState({ events, students, professors, classes })

			new Chart(chart1.current, {
				type: 'pie',
				data: {
					datasets: [{
						label: 'Estudiantes por niveles',
						data: Object.keys(studentsPerLevel).map(key => studentsPerLevel[key]),
						backgroundColor: Object.keys(studentsPerLevel).map(() => '#' + Math.floor(Math.random() * 16777215).toString(16))
					}],
					labels: Object.keys(studentsPerLevel),
				}
			});

			new Chart(chart2.current, {
				type: 'bar',
				data: {
					datasets: [{
						label: 'Créditos',
						data: [studentCredits.low.count, studentCredits.middle.count, studentCredits.hight.count],
						backgroundColor: Object.keys(studentsPerLevel).map(() => '#' + Math.floor(Math.random() * 16777215).toString(16))
					}],
					labels: [studentCredits.low.label, studentCredits.middle.label, studentCredits.hight.label]
				},
				options: {
					scales: {
						yAxes: [{ ticks: { suggestedMax: Math.max(studentCredits.low.count, Math.max(studentCredits.middle.count, studentCredits.hight.count)) + 2 } }]
					}
				}
			})

			new Chart(chart3.current, {
				type: 'line',
				data: {
					datasets: [{
						label: 'Cantidad de clases',
						data: Object.keys(classesPerYears).map(year => classesPerYears[year]),
						lineTension: 0.25
					}],
					labels: Object.keys(classesPerYears)
				}
			})
		}
		// eslint-disable-next-line	
	}, [dashboard]);

	return (
		<div className="row">
			<div className="col-12">
				<div className="row">
					<div className="col-lg-4">
						<div className="card blue lighten-1">
							<div className="card-content white-text d-flex align-items-center">
								<span className="card-title text-white mr-3 d-flex"><i className="material-icons medium">school</i></span>
								<p className="h4">Estudiantes: {state.students}</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="card green lighten-1">
							<div className="card-content white-text d-flex align-items-center">
								<span className="card-title text-white mr-3 d-flex"><i className="material-icons medium">streetview</i></span>
								<span className="h4">Profesores: {state.professors}</span>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="card orange lighten-1">
							<div className="card-content white-text d-flex align-items-center">
								<span className="card-title text-white mr-3 d-flex"><i className="material-icons medium">chrome_reader_mode</i></span>
								<p className="h4">Clases: {state.classes}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="col-12">
				<div className="row">
					<div className="col-lg-6 mb-3">
						<div className="card white h-100">
							<div className="card-content h-100" style={{ minHeight: '470px' }}>
								<Calendar localizer={localizer} events={state.events} culture="es" />
							</div>
						</div>
					</div>

					<div className="col-lg-6 mb-3">
						<div className="card white h-100">
							<div className="card-content">
								<span className="card-title">Clases impartidas</span>
								<canvas ref={chart3}></canvas>
							</div>
						</div>
					</div>

					<div className="col-lg-6 mb-3">
						<div className="card white h-100">
							<div className="card-content">
								<span className="card-title">Estudiantes por niveles</span>
								<canvas ref={chart1}></canvas>
							</div>
						</div>
					</div>

					{/* <div className="col-lg-6 mb-3">
						<Testimonials/>
					</div> */}

					<div className="col-lg-6 mb-3">
						<div className="card white h-100">
							<div className="card-content">
								<span className="card-title">Creditos de estudiantes</span>
								<canvas ref={chart2}></canvas>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}