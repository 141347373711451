import { gql } from "@apollo/client";

export const FRAGMENT_BASIC_PROFESOR = gql`fragment basicProfesorFragment on Profesor {
  id
  name
  lastname
  country
  email
  phone
  notes
  title
  active
  institution
  username
}`;