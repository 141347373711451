export interface Environments {
  production: boolean;
  serverUrl: string;
  graphql: string;
  accessToken: string;
  secureToken: string;
  userInfo: string;
}

export const environments: Environments = {
  production: process.env.REACT_APP_PRODUCTION === 'true',
  serverUrl: process.env.REACT_APP_SERVER_URL,
  graphql: process.env.REACT_APP_GRAPHQL,
  accessToken: process.env.REACT_APP_STOREFRONT_ACCESS_TOKEN,
  secureToken: process.env.REACT_APP_AUTH_TOKEN,
  userInfo: process.env.REACT_APP_USER_INFO
}
