
export function splitPages(arr: any[], size: number, count?: number) {

  if(count){
    let ret = Array(Math.ceil(count/size)).fill([]); 
    ret[0] = arr;
    return ret;
  }
    
  let ret = [];
  let cur = [...arr];
  while (cur.length) {
    ret.push(cur.splice(0, size));    
  }
  return ret;
}

export function toast(context: 'success' | 'warning' | 'danger', text: string) {
  M.toast({html: text, classes: `alert alert-${context}`})
}

export function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export function paginationFieldPolicy<T>() {
  return {
    merge(existing: T[] = [], incoming: T[] = [], { args, readField }) {
      const merged = existing ? existing.slice(0) : [];
      // Insertar los nuevos elementos en su lugar adecuado
      // Dado por los argumentos offset y limit
      const end = args.start + Math.min(args.limit, incoming.length);
      for (let i = args.start; i < end; ++i) {
        merged[i] = incoming[i - args.start];
      }
      return merged;
    },
    read(existing: T[], { args }) {
      // En la primera lectura, antes de haber introducido datos en la
      // cache, se devolverá undefined, que indica que no existe el campo
      const page =
        existing && existing.slice(args.start, args.start + args.limit);
      // Comprobamos que existen datos entre los límites indicados
      // en los argumentos
      if (page && page.length > 0) {
        return page;
      }
    }
  };
}
