import React from 'react';
interface IProps {
  color?: string;
  size?: string;
}
export function SpinnerCircle({ color, size }: IProps ) {
  return (
  <div className="spinner">
    <div className={`preloader-wrapper ${size || 'small'} active`}>
      <div className={`spinner-layer spinner-${ color || 'default'}-only`}>
        <div className="circle-clipper left">
          <div className="circle"></div>
        </div><div className="gap-patch">
          <div className="circle"></div>
        </div><div className="circle-clipper right">
          <div className="circle"></div>
        </div>
      </div>
    </div>
  </div>
  );
}