import React from 'react';
import { withRouter } from 'react-router-dom';

export function withRouterAndRef<T>(Wrapped) {
  const WithRouter = withRouter<any, any>(({ forwardRef, ...otherProps }) => (
    <Wrapped ref={forwardRef} {...otherProps} />
  ))
  const WithRouterAndRef = React.forwardRef<any, T>((props, ref) => (
    <WithRouter {...props} forwardRef={ref} />
  ))
  const name = Wrapped.displayName || Wrapped.name
  WithRouterAndRef.displayName = `withRouterAndRef(${name})`
  return WithRouterAndRef;
}