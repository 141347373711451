import { gql } from "@apollo/client";

export const ALL_ENTITIES = gql`
query allEntities {
  profesorsConnection {
    values {
      id
      name
      lastname
    }
 }
  
  studentsConnection {
    values {
      id
      name
      lastname
    }
 }
  
  classroomsConnection {
    values {
      id
      address
    }
 }
  
  departmentsConnection {
    values {
      id
      name
    }
 }
  subjectsConnection {
    values {
      id
      name
      clase {
        asignatura { name }
      }
    }
  }

classesConnection {
    values {
      id
      asignatura { name }
    }
  }
}
`;