import { gql } from "@apollo/client";

export const FRAGMENT_CLASS = gql`
fragment classFragment on Class {
  id
  created_at
  updated_at
  level
  startDate
  finishDate
  area
  credits
  facetoface
  cathegory
  eventsState
  state
  notes
  asignatura {
    id
    name
    departamento { name }
  }
}`;
