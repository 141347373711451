import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { client } from '../../../../core/graphql';
import { CURRENT_USER } from '../../../../core/graphql/quering';
import { RouteComponentProps } from 'react-router-dom';
import { STUDENTS } from '../../../../core/graphql/quering/queries/students';
// import { Collapsible, CollapsibleItem, Icon } from 'react-materialize';
import "./style.css";
// import auth from '../../../../core/graphql/cache/auth';
export class Nav extends Component<RouteComponentProps, any> {
  state = {
    collapseItem: 'collapse',
    arrow: 'keyboard_arrow_left',
    auth: null,
    student: null
  }

  componentDidMount() {
    let auth = client.readQuery({ query: CURRENT_USER });
    this.setState({
      ...this.state,
      auth
    })
    
    client.query({
      query: STUDENTS,
      variables: { where: { username: auth.currentUser?.username } }
    }).then(student => {
      this.setState({ ...this.state, student: student?.data?.studentsConnection?.values[0] })
    })
      .catch(ee => console.log(ee))
  }

  hideSidenav(e?) {
    const toggler: HTMLButtonElement = document.querySelector('.navbar-toggler');
    if (toggler) {
      toggler.click();
    }
    if (e) {
      e.stopPropagation();
    }
  }
  collapseItem() {
    const value = this.state.collapseItem === 'collapse' ? 'collapse in' : 'collapse';
    this.setState({ ...this.state, collapseItem: value });
  }

  toggleIcon() {
    if (this.state.arrow === 'keyboard_arrow_left') {
      this.setState({
        arrow: 'keyboard_arrow_right'
      })
    } else {
      this.setState({
        arrow: 'keyboard_arrow_left'
      })
    }
  }
  render() {

    const role = this.state?.auth?.currentUser?.role?.name;
    const isAdmin = role === 'ROLE_ADMIN';
    const isProfesor = role === 'ROLE_PROFESOR';
    const isStudent = role === 'ROLE_STUDENT';

    const routes = [
      {
        icon: 'school',
        link: '/students',
        label: 'Estudiantes',
        show:  isAdmin || isProfesor
      },
      {
        icon: 'school',
        link: `/students/${this.state?.student?.id}`,
        label: 'Mi perfil',
        show: isStudent
      },
      {
        icon: 'streetview',
        link: '/teachers',
        label: 'Profesores',
        show: isAdmin
      },
      {
        icon: 'chrome_reader_mode',
        link: '/classes',
        label: 'Clases',
        show: true
      },
      {
        icon: 'import_contacts',
        link: '/subjects',
        label: 'Asignaturas',
        show: isAdmin || isProfesor
      },
      {
        icon: 'account_balance',
        link: '/classrooms',
        label: 'Aulas',
        show: isAdmin
      },
      {
        icon: 'store',
        link: '/departments',
        label: 'Departamentos',
        show: isAdmin
      },
    ];

    const historic = [
      {
        icon: 'school',
        link: '/students?actives=false',
        label: 'Estudiantes inactivos',
        show: isAdmin
      },
      {
        icon: 'streetview',
        link: '/teachers?actives=false',
        label: 'Profesores inactivos',
        show: isAdmin
      },
    ]

    return (
      <div id="sidebar" className="sidebar" data-color="purple" data-background-color="white" data-image="../assets/img/sidebar-5.jpg"
        style={{ transition: '300ms' }}>
        <div className="logo py-0">
          <div className="mx-2">
            <Link to="/" className="">
              <img className="img-fluid" src="/logo-2.png" alt="Logo" />
            </Link>
          </div>
        </div>
        <div className="sidebar-wrapper h-auto">

          <ul className="nav" style={{ overflowX: 'hidden' }}>
          {/* <li className='nav-item' onClick={() => this.collapseItem()}>
            <Link to={"#"} className="nav-link" title={"hk"}>
              <i className="material-icons">{"school"}</i>
              <p>{"kI"}</p>
            </Link>
            <ul style={{ overflowX: 'hidden' }} aria-expanded="true" className={this.state.collapseItem + "nav"}>
              <li className='nav-item'>
                <Link to={"#"} className="nav-link" onClick={(e) => this.hideSidenav(e)} title={"hk"}>
                  <i className="material-icons">{"school"}</i>
                  <p>{"kk"}</p>
                </Link></li>
              <li className='nav-item'>
                <Link to={"#"} className="nav-link" onClick={() => this.hideSidenav()} title={"hk"}>
                  <i className="material-icons">{"school"}</i>
                  <p>{"kk"}</p>
                </Link>
              </li>
            </ul>
          </li> */}
            {routes.filter(r => r.show).map((r, i) => (
              <li className={`nav-item ${(this.props.location?.pathname || '').startsWith(r.link) && !this.props.location?.search ? 'active' : ''}`} key={i}>
                <Link to={r.link} className="nav-link" onClick={() => this.hideSidenav()} title={r.label}>
                  <i className="material-icons">{r.icon}</i>
                  <p>{r.label}</p>
                </Link>
              </li>
            ))}
            {/* </ul> */}

            {/* <ul className="nav" style={{ overflowX: 'hidden' }}> */}
            {historic.filter(r => r.show).map((r, i) => (
              <li className={`nav-item ${(this.props.location?.pathname + (this.props.location.search || '')).startsWith(r.link) ? 'active' : ''}`} key={i}>
                <Link to={r.link} className="nav-link" onClick={() => this.hideSidenav()} title={r.label}>
                  <p>{r.label}</p>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <a href="#!" id="slim" onClick={(ev) => {
          ev.preventDefault();
          this.toggleIcon();
        }}>
          <i className="material-icons">{this.state.arrow}</i>
        </a>
      </div>
    );
  }
}

export const Sidenav = withRouter(Nav);