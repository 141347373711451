import React, { useState, useEffect } from "react";
import { Login } from "../auth/components";
import { Dashboard } from "./components";
import { BrowserRouter as Router } from 'react-router-dom';
import { CURRENT_USER } from "../../core/graphql/quering";
import { client } from "../../core/graphql";
import ScrollToTop from '../../shared/utils/ScrollToTop';
import ErrorBoundary from "./ErrorBoundary";
// import TestComponent from "../../core/TestComponent";
// import { Report } from "../student/components";
// import { StudentDetails } from "../student/components";
// import { Report } from "../student/components";
// import { UsersPermissionsRole } from "../../core/models/interfaces";
// import { ROLES } from "../../core/graphql/quering/queries/users";

//TODO desde cada DataTable pasar la query, para ejecutarla en el componente
  
declare var window;

function setSidebar() {
  let $sidebar = window.$sidebar;
  let $slim = window.$slim;
  let md = window.md;

  if (!$sidebar || !$slim) {
    //console.log('aki esta')
    $sidebar = $('.sidebar');
    $slim = $('#slim');

    $slim.on('click', () => {
      if ($sidebar.hasClass('slim')) {
        $sidebar.removeClass('slim');
      } else {
        $sidebar.addClass('slim');
      }
    })

    window.$sidebar = $sidebar;
    if (window.$sidebar) {
      md.initSidebarsCheck();
      md.checkSidebarImage();
    }
  }
}

export function Shell() {
  const [auth, setAuth] = useState(false);
  client.watchQuery({ query: CURRENT_USER }).subscribe(res => {
    setAuth(!!res.data?.currentUser);
  }, console.log)

  useEffect(() => {

    if (auth) {
      setSidebar();
    }
  }, [auth])

  return (
    <ErrorBoundary>
      <Router>
        <ScrollToTop />
        {auth ? <Dashboard /> : <Login />}
        {/* <Dashboard /> */}
      </Router>
    </ErrorBoundary>
  )
}
