import React, { Component } from 'react';
import { Header } from '../header';
import { Main } from '../main';
import { Footer } from '../footer';
import './Dashboard.css';
import { Sidenav } from '../sidenav';
//import { event } from '../../../../core/models/interfaces';
//import * as moment from 'moment';

export class Dashboard extends Component  {

  render() {
 
    return (
      // <Router>
        <div className="wrapper">
          <Sidenav />
          <div className="main-panel">
            <Header />
            <div className="content">
              <Main />
            </div>
            <Footer />
          </div>
        </div>
      // </Router>
    );
  }
}