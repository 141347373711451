import React, { Component, createRef } from 'react';
import { UserForm } from '../../form/UserForm';
// import { withRouterAndRef } from '../../utils/withRouterInnerRef';
import { DataTable, IDataTableProps } from './DataTable';
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';

interface IProps extends IDataTableProps {
  setPerPage?: Function;
  onFilterData?: Function;
  roles?: any;
  showAddForm?: boolean;
  onAdd?: Function;
  listLabel?: string;
  addLabel?: string;
  loading?: boolean;
  addFormBeforeContent?: any;
  validate?: Function;
  currentPage?: number;
}

interface IState {
  adminOptions?: boolean;
  cols: any;
  pages: any[][];
  perPageOpts: number[];
  perPage: number;
  currentPage: number;
  count: number;
  // avatarFile?: File;
  // ciOneFile?: File;
  // ciTwoFile?: File;
  // avatarPreview?: any;
  // ciOnePreview?: any;
  // ciTwoPreview?: any;
}

export class SimpleTable extends Component<IProps, IState> {
  tableTab = createRef<HTMLAnchorElement>();
  addFormTab = createRef<HTMLAnchorElement>();
  exportExcel = createRef<HTMLButtonElement>();

  constructor(props) {
    super(props);
    this.state = {
      adminOptions: true,
      cols: props.cols,
      pages: props.pages,
      currentPage: props.currentPage || 1,
      count: props.count || 0,
      perPage: props.perPage || 10,
      perPageOpts: [1, 5, 10, 15, 20]
    };
  }

  componentDidMount() {

    const elems = document.querySelectorAll('select');
    M.FormSelect.init(elems, {
      classes: 'pagination-select text-primary border-bottom',
      dropdownOptions: {
        constrainWidth: false
      }
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.propsToState(props);
  }

  propsToState(props: IProps) {
    const state: IState = {
      ...this.state,
      currentPage: props.currentPage || 1,
      cols: props.cols,
      pages: props.pages,
      count: props.count || 0,
      perPage: props.perPage || this.state.perPage
    };
    this.setState(state);
  }

  navigateTo(id) {
    if (this.props.navigationOnClick) {
      const { history, navigationBase } = this.props;
      history.push(`${navigationBase}/${id}`);
    }
  }

  /*
  const onAddStudent = (data) => {
    // console.log("Estado antes de adicionar las imagenes del estudiantess");
    // console.log(state);

    if (state.avatarFile) {

      client.mutate<{ upload: UploadFile }>({
        mutation: MUTATION_UPLOAD,
        variables: { file: state.avatarFile }
      }).then(({ data: res }) => {
        data.photo = res.upload?.id;
      })
        .catch((err) => {
          console.log(err);
          toast('danger', 'Disculpe, el avatar del estudiante no se a podido guardar')
        })
    }

    if (state.ciOneFile) {

      client.mutate<{ upload: UploadFile }>({
        mutation: MUTATION_UPLOAD,
        variables: { file: state.ciOneFile }
      }).then(({ data: res2 }) => {
        data.ciPhoto = res2.upload.id;

      })
        .catch((err) => {
          console.log(err);
          toast('danger', 'Disculpe, la foto frontal del carnet del estudiante no se ha podido guardar')
        })

    }

    if (state.ciTwoFile) {

      client.mutate<{ upload: UploadFile }>({
        mutation: MUTATION_UPLOAD,
        variables: { file: state.ciTwoFile }
      }).then(({ data: res3 }) => {
        data.ciPhoto2 = res3.upload.id;
      })
        .catch((err) => {
          console.log(err);
          toast('danger', 'Disculpe, la foto posterior del carnet del estudiante no se ha podido guardar')
        })

    }

    console.log("Antes de addstu");
    console.log(data);
  */
  onAdd(fields) {
    if (this.props.onAdd) {
      let data = {};
      fields.forEach(field => data[field?.name] = field.value);
      this.props.onAdd(data);
    }
  }

  viewTable() {
    this.tableTab.current.click();
  }

  viewAddForm() {
    if (this.props.showAddForm) {
      this.addFormTab.current.click();
    }
  }

  render() {

    return (
      <>
        <div className="card">
          <div className="card-header card-header-primary blue">
            <div className="float-left">
              <h4 className="card-title ">{this.props.title}</h4>
              <p className="card-category"> {this.props.subtitle}</p>
            </div>

            <div className="nav-tabs-navigation float-right">
              <div className="nav-tabs-wrapper">
                {/*
                <div className="btn btn-success" onClick={() => { this.setState({ ...this.state, adminOptions: false }, () => this.exportExcel.current.click()) }}>
                  ldfkjdf
                </div> 
                */}
                <ul className="nav nav-tabs" data-tabs="tabs">
                  {/* onClick={()=> this.setState({...this.state, adminOptions: false}, ()=>{setTimeout(()=>{this.setState({...this.state, adminOptions: true})}, 2000)})} */}
                  {/* <li className="nav-item">

                    <ReactHTMLTableToExcel
                      id="botonExportarExcel"
                      // ref={this.exportExcel}
                      table="DataTable"
                      filename="fileExcel"
                      sheet="pagina 1"
                      buttonText="Exportar a Excel">
                    </ReactHTMLTableToExcel>

                  </li>  */}
                  <li className="nav-item">
                    <a ref={this.tableTab} className="nav-link active show" href="#list" data-toggle="tab">
                      <i className="material-icons">list</i> {this.props.listLabel || 'Listado'}
                      <div className="ripple-container"></div>
                      <div className="ripple-container"></div></a>
                  </li>
                  {this.props.showAddForm && <li className="nav-item">
                    <a ref={this.addFormTab} className="nav-link" href="#add" data-toggle="tab">
                      <i className="material-icons">add</i> {this.props.addLabel || 'Adicionar'}
                      <div className="ripple-container"></div>
                      <div className="ripple-container"></div></a>
                  </li>}
                </ul>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="tab-content">
              <div className="tab-pane active show" id="list">
                <DataTable
                  onFilterData={this.props.onFilterData}
                  cols={this.state.cols}
                  pages={this.state.pages}
                  count={this.state.count}
                  roles={this.props?.roles}
                  perPage={this.state.perPage}
                  setPerPage={this.props?.setPerPage}
                  title={this.props.title}
                  subtitle={this.props.subtitle}
                  navigationOnClick={this.props.navigationOnClick}
                  navigationBase={this.props.navigationBase}
                  onEmptyPage={this.props.onEmptyPage}
                  onDelete={(id) => { this.props.onDelete && this.props.onDelete(id) }}
                  deleteModalText={this.props?.deleteModalText}
                  addons={this.props.addons}
                  report={this.props.report}
                  currentPage={this.state.currentPage}
                  adminOptions={this.state?.adminOptions}
                // beforeContent={this.props.addFormBeforeContent}
                />
              </div>
              {this.props.showAddForm && <div className="tab-pane" id="add">
                {this.props.addFormBeforeContent}
                <UserForm validate={this.props.validate} roles={this.props?.roles} fields={this.props.fields} title="Student Information" onSubmit={(fields) => this.onAdd(fields)} />
              </div>}
            </div>
          </div>
        </div>
      </>
    )
  }
}

// export const SimpleTable = withRouter<IProps, any>(Table);

// export const SimpleTable = withRouterAndRef<IProps>(Table);
