import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Home } from '../home';
import { SpinnerCircle } from '../../../../shared/components';
// import TestComponent from '../../../../core/TestComponent';
// import TestComponent from '../../../../core/TestComponent';

const StudentModule = React.lazy(() => import('../../../student'))
const TeacherModule = React.lazy(() => import('../../../teachers'))
const ClassModule = React.lazy(() => import('../../../classes'))
const SubjectModule = React.lazy(() => import('../../../subjects'))
const ClassroomModule = React.lazy(() => import('../../../classrooms'))
const DeparmentModule = React.lazy(() => import('../../../departments'))
const Profile = React.lazy(() => import('../../../auth/components/profile/Profile'))

export function Main() {
  return (
    <main>
      <Suspense fallback={<div className="loading-container transparent"><SpinnerCircle color="yellow" size="big"/></div>}>
        <Switch>
          <Route path="/profile" component={Profile}></Route>
          <Route path="/students" component={StudentModule}></Route>
          <Route path="/teachers" component={TeacherModule}></Route>
          <Route path="/classes" component={ClassModule}></Route>
          <Route path="/subjects" component={SubjectModule}></Route>
          <Route path="/classrooms" component={ClassroomModule}></Route>
          <Route path="/departments" component={DeparmentModule}></Route>
          <Route path="/" exact component={Home}></Route>
          {/* <Route path="/" exact component={TestComponent}></Route> */}
          <Route component={() => (<div>Not found</div>)} />
        </Switch>
      </Suspense>
    </main>
  )
}

//TestComponent 

