import React, { FormEvent, useState } from 'react';
import { QUERY_LOGIN, CURRENT_USER } from '../../../../core/graphql/quering';
import { useHistory, withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { environments } from '../../../../environments';
import { SpinnerCircle } from '../../../../shared/components/spinner/SpinnerCircle';
import { client } from '../../../../core/graphql';
import { STUDENTS } from '../../../../core/graphql/quering/queries';
// import { QUERY_PROFESORS } from '../../../../core/graphql/quering/queries/profesors';
import { EXISTS_USER } from '../../../../core/graphql/quering/queries/users';

function _Login() {
  const [identifier, setIdentifier] = useState({ value: '', touched: false });
  const [password, setpassword] = useState({ value: '', touched: false });
  const [showPassword, togglePassword] = useState(false);
  const history = useHistory();

  const [login, { loading: mutationLoading, error: mutationError }] = useMutation(QUERY_LOGIN, {
    onError: () => { /* setpassword({ value: '', touched: true }) */ }
  });

  const onSubmit = (ev: FormEvent) => {
    ev.preventDefault();
    login({ variables: { identifier: identifier.value, password: password.value } })
      .then((data: any) => {
        
        if (data) {
          // localStorage.setItem(environments.secureToken, data.data.login.jwt)
          // localStorage.setItem(environments.userInfo, JSON.stringify(data.data.login.user))
          const token = data.data.login.jwt;
          const headers = {
            Authorization: token ? `Bearer ${token}` : ''
          }

          if (data.data.login.user.role.name === 'ROLE_STUDENT') {
            // localStorage.setItem(environments.secureToken, data.data.login.jwt)
            // localStorage.setItem(environments.userInfo, JSON.stringify( data.data.login.user))

            client.query({
              query: STUDENTS,
              variables: { where: { username: data.data.login.user.username } },
              context: { headers }
            })
              .then(res => {

                if (res.data.studentsConnection?.values?.length) {

                  const studentInfo = {
                    ...data.data.login.user,
                    ...res.data.studentsConnection?.values[0]
                  }
                  localStorage.setItem(environments.secureToken, data.data.login.jwt);
                  localStorage.setItem(environments.userInfo, JSON.stringify(studentInfo));
                  client.writeQuery({
                    query: CURRENT_USER,
                    data: {
                      currentUser: studentInfo
                    }
                  })
                  // history.push('/')
                  history.push('/profile');
                  // history.push(`/students/${res.data.studentsConnection?.values[0].id}`);
                }
              })
              .catch(console.error)
          } else if (data.data.login.user.role.name === 'ROLE_PROFESOR') {
            //  history.push('/');
            client.query({
              query: EXISTS_USER, //query: QUERY_PROFESORS,
              variables: { where: { username: data.data.login.user.username } },
              context: { headers }
            })
              .then(res => {
                if (res.data.profesorsConnection?.values?.length) {
                  const profesorInfo = {
                    ...data.data.login.user,
                    ...res.data.profesorsConnection?.values[0]
                  }

                  localStorage.setItem(environments.secureToken, data.data.login.jwt)
                  localStorage.setItem(environments.userInfo, JSON.stringify(profesorInfo))

                  client.writeQuery({
                    query: CURRENT_USER,
                    data: {
                      currentUser: profesorInfo
                    }
                  })

                  history.push('/profile');
                } else if (res.data.studentsConnection?.values?.length) {
                  const studentInfo = {
                    ...data.data.login.user,
                    ...res.data.studentsConnection?.values[0]
                  }
                  localStorage.setItem(environments.secureToken, data.data.login.jwt)
                  localStorage.setItem(environments.userInfo, JSON.stringify(studentInfo))
                  client.writeQuery({
                    query: CURRENT_USER,
                    data: {
                      currentUser: studentInfo
                    }
                  })
                }
                history.push(`/students/${res.data.studentsConnection?.values[0].id}`);
              })
              .catch(console.error)
          }
          else if (data.data.login.user.role.name === 'ROLE_ADMIN') {

            client.query({
              query: EXISTS_USER, 
              variables: { where: { username: data.data.login.user.username } },
              context: { headers }
            })
              .then(res => {

                if (res.data.profesorsConnection?.values?.length) {
                  const profesorInfo = {
                    ...data.data.login.user,
                    ...res.data.profesorsConnection?.values[0]
                  }

                  localStorage.setItem(environments.secureToken, data.data.login.jwt)
                  localStorage.setItem(environments.userInfo, JSON.stringify(profesorInfo))

                  client.writeQuery({
                    query: CURRENT_USER,
                    data: {
                      currentUser: profesorInfo
                    }
                  })

                  history.push('/');
                  // history.push('/profile');
                } else if (res.data.studentsConnection?.values?.length) {
                  // console.log(res.data.studentsConnection);

                  const studentInfo = {
                    ...data.data.login.user,
                    ...res.data.studentsConnection?.values[0]
                  }
                  localStorage.setItem(environments.secureToken, data.data.login.jwt)
                  localStorage.setItem(environments.userInfo, JSON.stringify(studentInfo))
                  client.writeQuery({
                    query: CURRENT_USER,
                    data: {
                      currentUser: studentInfo
                    }
                  })
                  history.push('/');
                } else {
                  localStorage.setItem(environments.secureToken, data.data.login.jwt);
                  localStorage.setItem(environments.userInfo, JSON.stringify(data.data.login.user));

                  client.writeQuery({
                    query: CURRENT_USER,
                    data: {
                      currentUser: data.data.login.user
                    }
                  })
                  history.push('/');
                }

                // history.push(`/students/${res.data.studentsConnection?.values[0].id}`);
              })
              .catch(
                // ()=>{
                // localStorage.setItem(environments.secureToken, data.data.login.jwt);
                // localStorage.setItem(environments.userInfo, JSON.stringify(data.data.login.user));

                // client.writeQuery({
                //   query: CURRENT_USER,
                //   data: {
                //     currentUser: data.data.login.user
                //   }
                // })
                // history.push('/');          
              console.error
              )
          }

          // if (data.data.login.user.role.name === 'ROLE_ADMIN') {
          //   history.push('/');
          // } else {
          //   history.push('/profile')
          // }
        }
      })
  }

  const handlePasswordChange = (event) => {
    setpassword({ ...password, value: event.target.value })
  }
  const handleIdentifierChange = (event) => {
    setIdentifier({ ...identifier, value: event.target.value })
  }

  const handlePasswordBlur = () => {
    setpassword({ ...password, touched: true })
  }
  const handleIdentifierBlur = () => {
    if (!identifier.touched) {
      setIdentifier({ ...identifier, touched: true })
    }
  }

  return (
    <div className="grey lighten-3" style={{ height: '90vh' }}>
      <div className="container h-100">
        <div className="row h-100 valign-wrapper">
          <div className="col s12">
            <div className="row justify-content-center">
              <div className="col-md-8 col-xl-6 z-depth-2 white">
                {mutationError &&
                  <div className="alert alert-danger center-align my-4">
                    {mutationError.graphQLErrors[0]?.extensions.exception.data?.message[0].messages[0].message || 'Connection losed'}
                  </div>
                }
                <form className="py-5 px-3" onSubmit={onSubmit}>
                  <span className="bmd-form-group mb-2">
                    <div className="input-group mb-5">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="material-icons">person</i>
                        </span>
                      </div>
                      <input id="identifier" type="text" onChange={(e) => handleIdentifierChange(e)} onBlur={() => handleIdentifierBlur()}
                        className={`form-control ${(!identifier.value && identifier.touched) && 'invalid'}`} />
                      <label htmlFor="identifier" className="bmd-label-floating pl-5 ml-3">Identificador</label>
                    </div>
                  </span>
                  <span className="bmd-form-group mb-2">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" style={{ cursor: 'pointer' }} onClick={(e) => {
                          e.preventDefault();
                          togglePassword(!showPassword);
                        }}>
                          <i className="material-icons">{showPassword ? 'visibility' : 'visibility_off'}</i>
                        </span>
                      </div>
                      <input id="password" type={showPassword ? 'text' : 'password'} onChange={(e) => handlePasswordChange(e)} onBlur={() => handlePasswordBlur()}
                        className={`form-control ${(!password.value && password.touched) && 'invalid'}`} />
                      <label htmlFor="password" className="bmd-label-floating pl-5 ml-3">Contraseña</label>
                    </div>
                  </span>

                  <p className="text-center">
                    <label>
                      <input type="checkbox" />
                      <span>Recordarme</span>
                    </label>
                  </p>
                  <button disabled={(!identifier.value || !password.value || mutationLoading)} type="submit" className="btn btn-primary btn-block">
                    {!mutationLoading && 'Login'}
                    {mutationLoading && <div className="login">
                      <SpinnerCircle size="small" />
                    </div>}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const Login = withRouter(_Login);
