import { gql } from '@apollo/client';

export const QUERY_LOGIN = gql`
mutation login($identifier: String!, $password: String!) {
  login(input: { identifier: $identifier, password: $password }) {
    jwt
    user {
      username
      email
      confirmed
      blocked
      role {
        id
        name
        description
        type
      }
    }
  }
}`;

export const QUERY_USER_INFORMATION = gql`
query userInformation($username:String!) {
  users(limit: 1, where:{username: $username}) {
    id
    email
    username
    role {
      name
      id
      description
      type
      
    }
  }
}
`;

export const IS_LOGGED_IN = gql`
query IsLoggedIn {
  isLoggedIn @client
}`;

export const CURRENT_USER = gql`
query CurrentUser {
  currentUser @client
}
`;