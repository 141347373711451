import { gql } from '@apollo/client';
import { FRAGMENT_STUDENT } from '../fragments/students';
import { FRAGMENT_CLASS } from '../fragments/class';
import { FRAGMENT_CLASSROOM } from '../fragments/classroom';

export const STUDENTS = gql`
query GetStudents($limit: Int, $sort: String, $start: Int, $where: JSON) @client {
  studentsConnection(limit: $limit, sort: $sort, start: $start, where: $where) {
    values {
      ...studentFragment
      clase {
        profesor {
          id
        }
      }
    }
    aggregate {
      count
    }
  }
}
${FRAGMENT_STUDENT}`;

export const STUDENTS_FILTER = gql`
query filterStudents($where: JSON, $limit: Int, $sort: String) {
  students(limit: $limit, sort: $sort, where:$where ) {
    ...studentFragment
      clase {
        profesor {
          id
        }
      }
  }
}${FRAGMENT_STUDENT}`;

export const STUDENT = gql`
query Student($limit: Int, $start: Int) {
  students(limit: $limit, start: $start) {
    name
  }
}`;

export const STUDENTS_COUNT = gql`
query countStudents {
  studentsConnection {
    aggregate {
      count
    }
  }
}`;

export const STUDENT_INFORMATION = gql`
query userInformation($id:ID!, $classLimit:Int, $classStart:Int) {
  student(id:$id) {
    ...studentFragment
    clase(limit:$classLimit, start:$classStart) {
      ...classFragment
    }
    aula {
      ...classroomFragment
    }
    devices
    eventsActive
    otherTitles
  }
}
${FRAGMENT_STUDENT}
${FRAGMENT_CLASS}
${FRAGMENT_CLASSROOM}`;

export const EXISTS_EXTUDENT = gql`
query existsStudents ($username:String) {
  students (where: { username: $username }) {
    id
  }
}`;

export const PHOTO_HEADER = gql`
query photoStudent($username:String) {
  students (where: { username: $username }, limit: 1) {
    photo{
    alternativeText
    url
  }
  }
  profesors(where: { username: $username }, limit: 1) {
    photo{
    alternativeText
    url
  }
  }
}`;
