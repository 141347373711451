import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter, useLocation } from 'react-router-dom';

const ScrollToTop = (props: RouteComponentProps) => {

  const { pathname } = useLocation();

  useEffect(() => {
    if (props.history.action === 'POP') {
      return;
    }

    let hash = props.location.hash;
    if (hash) {
      let element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    } else {
      window.scrollTo(0, 0);
    }
  // eslint-disable-next-line
  }, [pathname])

  return ( <></> );
}

export default withRouter(ScrollToTop);