import React from 'react';
import { Link } from 'react-router-dom';

export function Footer() {
  return (
    <footer className="footer">
        <div className="container-fluid">
          <nav className="float-left">
            <ul>
              <li>
                <Link to="/">
                  <img src="/logo-circle.png" alt="" style={{ maxWidth: '40px' }}/>
                </Link>
              </li>
              {/* <li>
                <a href="#!">
                  Ipsum
                </a>
              </li>
              <li>
                <a href="#!">
                  Sit
                </a>
              </li>
              <li>
                <a href="#!">
                  Amet
                </a>
              </li> */}
            </ul>
          </nav>
          <div className="copyright float-right">
            &copy;
            { new Date().getFullYear() }, Alianza Cristiana y Misionera de Cuba
          </div>
        </div>
      </footer>
  );
}