import { gql } from '@apollo/client';

export const FRAGMENT_STUDENT = gql`
fragment studentFragment on Student {
  id
  name
  lastname
  username
  ci
  email
  phone
  mobile
  municipality
  province
  zone
  neighborhood
  churchAddress
  studentAddress
  secularJob
  church
  ministry
  academyGrade
  levelFinished
  currentLevel
  califications
  devices
  otherTitles
  ciPhoto {
    alternativeText
    url
  }
  ciPhoto2 {
    alternativeText
    url
  }
  photo {
    alternativeText
    url
  }
  active
}`;