import React, { useState, useEffect, useRef } from 'react';
import { withRouterAndRef } from '../../utils/withRouterInnerRef';
import { Modal } from '../../modals/Modal';
import { ConfirmModal } from '../../modals/ConfirmModal';
import { splitPages } from '../../../core/utils/functions';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { CURRENT_USER } from '../../../core/graphql/quering';
// import { ENUM_STUDENT_ACADEMYGRADE } from '../../../core/models/interfaces';
// import { ENUM_STUDENT_ACADEMYGRADE } from '../../../core/models/interfaces';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { environments } from '../../../environments';
import { ENUM_STUDENT_ACADEMYGRADE } from '../../../core/models/interfaces';

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    rel?: string;
  }
}
export interface IDataTableProps {
  download?: boolean;
  onFilterData?: Function;
  roles?: any;
  ref?: any;
  cols: any;
  pages: any[][];
  count?: number;
  color?: string;
  title?: string;
  subtitle?: string;
  navigationOnClick?: boolean;
  navigationBase?: string;
  onEmptyPage?: Function;
  history?: any;
  fields?: any;
  perPage: number;
  currentPage?: number;
  perPageOpts?: number[];
  onDelete?: Function;
  addons?: any[];
  admin?: boolean;
  deleteModalText?: string;
  deleteModalTarget?: string;
  canView?: boolean;
  canDelete?: boolean;
  onEdit?: Function;
  editTarget?: string | boolean;
  adminOptions?: boolean;
  report?: string;
  setPerPage?: Function;
  addButton?: boolean;
  addButtonText?: string;
  addButtonTarget?: string;
  beforeContent?: any;
}

interface IState<T = any> {
  filterSearch?: String;
  cols: any;
  pages: T[][];
  perPageOpts: number[];
  perPage: number;
  currentPage: number;
  count: number;
  selected?: number;
  filtered?: any[][];
}

function applyTransform(value, transform) {
  // console.log(value, transform)
  if (!transform) {
    return value;
  }
  return transform(value);
}

function renderField(obj, field: string, transform?: Function, index?, first?) {
  if (typeof (obj) !== 'boolean' && !obj) {
    return applyTransform('', transform);
  }
  let ref = obj;
  const fields = field.split('.');
  for (let f of fields) {
    if (f === "academyGrade") {
      f = ENUM_STUDENT_ACADEMYGRADE[ref[f]];
      ref = f?.toLowerCase();
      ref = f;
    } else {
      ref = ref[f];
    }

    if (f === "id") {
      ref = first + index
    }

    if (typeof (ref) === 'number') {
      return applyTransform(ref.toString(), transform);
    }
    if (typeof (ref) !== 'boolean' && !ref) {
      return applyTransform(undefined, transform);
    }
  }
  if (typeof (ref) === 'number') {
    return applyTransform(ref.toString(), transform);
  }
  return applyTransform(typeof (ref) === 'boolean' ? (ref ? 'Si' : 'No') : ref, transform);
}

function Table(props: IDataTableProps) {
  const [state, setState] = useState<IState>({
    cols: props.cols,
    count: props.count || 0,
    pages: props.pages,
    currentPage: props.currentPage || 1,
    perPage: props.perPage || 10,
    perPageOpts: props.perPageOpts || [1, 5, 10, 15, 20]
  });
  const inputRef = useRef<HTMLInputElement>()

  const { data: auth } = useQuery(CURRENT_USER);
  const isAdmin = auth?.currentUser?.role.name === 'ROLE_ADMIN';
  const isProfesor = auth?.currentUser?.role.name === 'ROLE_PROFESOR';
  const adminOptions = typeof (props?.adminOptions) == 'undefined' ? true : props?.adminOptions;

  useEffect(() => {

    if (props.pages[state.currentPage - 1]?.some(i => !i) && props.onEmptyPage) {
      props.onEmptyPage(state.currentPage, state.perPage)
    }

    setState({
      cols: props.cols,
      count: props.count || 0,
      pages: props.pages,
      currentPage: props.currentPage || state.currentPage || 1,
      perPage: props.perPage || state.perPage,
      perPageOpts: props.perPageOpts || [1, 5, 10, 15, 20]
    })

    const subscription = fromEvent(inputRef.current, 'input').pipe(
      map((e: KeyboardEvent) => {
        e.preventDefault();
        e.stopPropagation();
        return (e.target as HTMLInputElement).value
      }
      ),
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((data) => onfilterSubmit(data))

    return () => subscription.unsubscribe();
    // eslint-disable-next-line
  }, [props])

  // const fields = studentFields(null);
  const pageCount = Math.ceil(state.count / state.perPage); //state.pages.length; //
  const from = Math.max(1, state.currentPage - 4);
  const to = Math.min(pageCount, from + 8);
  const pages = [];
  const first = state.perPage * (state.currentPage - 1) + (state.count ? 1 : 0);
  const last = Math.min(state.count, first + state.perPage - 1);

  for (let i = from; i <= to; i++) {
    pages.push(i);
  }

  const rangeInfo = (
    <p className="ml-auto">{first} - {last} de {state.count} elementos</p>
  );

  const skipToPage = async (e: React.MouseEvent, page: number) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.onEmptyPage) {
      await props.onEmptyPage(page, state.perPage);
    }

    setState({
      ...state,
      currentPage: page
    });
  }

  const onfilterSubmit = async (filter) => {

    if (props.onFilterData) {
      await props.onFilterData(filter);

      setState({
        ...state,
        currentPage: 1,
        filterSearch: null
      })
    } else {
      onfilterOld(filter)
    }
  }

  const setPerPage = (size: number) => {
    if (props.setPerPage) {
      props.setPerPage(size);
      setState({
        ...state,
        perPage: size
      })
    }

    setState({
      ...state,
      perPage: size,
      currentPage: 1,
      pages: splitPages(state.pages.reduce((a, b) => a.concat(b)), size)
    })
  }

  // const navigateTo = (id: number | string) => {
  //   if (props.navigationOnClick) {
  //     props.history?.push(`${props.navigationBase}/${id}`)
  //   }
  // }

  const onModalConfirm = () => {
    if (props.onDelete) {
      props.onDelete(state.selected)
    }
  }

  const onfilterOld = (filter: string) => {
    if (!filter) {
      setState({
        ...state,
        filtered: undefined,
        currentPage: 1
      })
    } else {
      filter = filter.toLowerCase();
      if (state.pages.length) {

        const filtered = state?.pages
          ?.reduce((arr, page) => arr?.concat(page))
          .map(item => {
            if (item.img) {
              delete item['img']
            }
            return item;
          })
          .filter(item => JSON.stringify(item).toLowerCase()?.indexOf(filter) >= 0);
        setState({
          ...state,
          filtered: splitPages(filtered, state?.perPage || 10),
          currentPage: 1
        })
      }
    }
  }

  const pagination = (
    <ul className="pagination" style={{ width: 'fit-content' }}>
      <li className={state.currentPage > 1 ? 'waves-effect' : 'disabled'}>
        <a href="#!" onClick={(e) => skipToPage(e, state.currentPage - 1)}><i className="material-icons">chevron_left</i></a>
      </li>
      {pages.map((p, i) => (
        <li className={p === state.currentPage ? 'active primary' : 'waves-effect'} key={i}>
          <a href="#!" onClick={(e) => skipToPage(e, p)}>{p}</a>
        </li>
      ))}
      <li className={state.currentPage < pageCount ? 'waves-effect' : 'disabled'}>
        <a href="#!" onClick={(e) => skipToPage(e, state.currentPage + 1)}><i className="material-icons">chevron_right</i></a>
      </li>
    </ul>
  );

  const hasFoot = state.cols?.reduce((res, col) => res = res || !!col.footer, false);

  return (

    <>
      <div className="tab-pane active show" id="list">
        {/* onSubmit={(ev)=>onfilterSubmit(ev)} */}
        <form >
          <div className="form-group bmd-form-group">
            <i className="material-icons prefix">search</i>
            <label className="bmd-label-floating">filtrar</label>
            {/* onInput={(ev) => onFilter(ev.currentTarget.value) */}
            <input type="search" ref={inputRef} className="form-control" />
          </div>
        </form>
        <div className="table-responsive">
          {/* id={props.id} */}
          <table className="table" id="DataTable">
            <thead className={`text-${props.color || 'primary'}`}>
              <tr>
                {state.cols.map((col, i) => (<th key={i} className={col.className}>{col.label}</th>))}
                {(props?.addons || []).map((addon, i) => (<th style={addon.style} className={addon.className} key={i}>{addon.label}</th>))}
                {adminOptions && <th style={{ width: '50px' }}></th>}
              </tr>
            </thead>
            <tbody>
              {
                state.pages.length === 0 &&
                <tr>
                  <td colSpan={state.cols.length + (props.addons || []).length + 1}>
                    <div className="text-center">No hay elementos para mostrar</div>
                  </td>
                </tr>
              }
              {state.pages.length > 0 && isProfesor && state.pages[state.currentPage - 1].length === 0 ?
                (<tr>
                  <td colSpan={state.cols.length + (props.addons || []).length + 1}>
                    <div className="text-center">Los elementos de esta pagina no estan disponibles para usted</div>
                  </td>
                </tr>) :
                ((state.filtered || state.pages)[state.currentPage - 1] || []).map((row, i) => {
                  return (
                    <tr key={i}>
                      {state.cols.map((col, j) => (
                        <td className={col.className} key={j}>
                          {renderField(row, col.field, col.transform, i, first) || '-'}
                        </td>
                      ))}
                      {(props?.addons || []).map((addon, i) => (<td style={addon.style} key={i}>{addon.html(row)}</td>))}
                      {adminOptions && <td id="options" className="td-actions text-right border-0">
                        {props?.download && <a href={environments.serverUrl + (row.files[0]?.url?.startsWith('/') ? row.files[0]?.url.substr(1) : row.files[0]?.url)} download={row?.files[0]} title="Descargar" className="btn btn-primary btn-link btn-sm" target="blank">
                          <i className="material-icons">download</i>
                        </a>}
                        {(props.onEdit && props.editTarget) && <button type="button" title="Editar" className="btn btn-primary btn-link btn-sm"
                          data-toggle="modal" data-target={props.editTarget} onClick={(ev) => { props.onEdit(row) }}>
                          <i className="material-icons">edit</i>
                        </button>}
                        {((isAdmin || isProfesor) && props.canView?.toString() !== 'false') && <Link to={`${props.navigationBase}/${row?.id}`} type="button" className="btn btn-primary btn-link btn-md" title="Ver">
                          <i className="material-icons">visibility</i>
                        </Link>}
                        {props?.report && <Link to={`/${props.report}/${row?.id}/report`} className="btn btn-primary btn-link btn-sm" title="Ver Reporte">
                          <i className="material-icons">insert_drive_file</i>
                        </Link>}
                        {(isAdmin && props.onDelete) && <button type="button" className="btn btn-danger btn-link btn-sm" title="Eliminar"
                          data-toggle="modal" data-target={props.deleteModalTarget || '#deleteStudent'} onClick={() => setState({ ...state, selected: row.id || row })}>
                          <i className="material-icons">close</i>
                        </button>}
                      </td>}
                    </tr>
                  )
                })}
            </tbody>
            {hasFoot && <tfoot>
              <tr>
                {state.cols.map((col, j) => (
                  <th key={j}>{col.footer}</th>
                ))}
              </tr>
            </tfoot>}
          </table>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            {props.addButton && <div className="d-flex w-100">
              <button className="btn btn-primary ml-auto" data-toggle="modal" data-target={props.addButtonTarget}>{props.addButtonText || 'Adicionar'}</button>
            </div>}
          </div>
          <div className="col-6 col-md-4">
            <div className="input-field" style={{ width: '150px' }}>
              <select defaultValue={state.perPage} onChange={(e) => setPerPage(+e.target.value)}>
                {state.perPageOpts.map((opt, i) => (
                  <option key={i} value={opt}>{opt}</option>
                ))}
              </select>
              <label>Elementos por página</label>
            </div>
          </div>
          <div className="col-6 col-md-4 ml-auto d-flex align-items-center"> {rangeInfo}</div>
          <div className="col-12 d-flex justify-content-center align-items-center">
            {pagination}
          </div>
        </div>
      </div>

      <Modal content={<ConfirmModal
        cancelLabel="Cancelar"
        confirmLabel="Confirmar"
        title=" "
        content={props?.deleteModalText}
        id={`${props.deleteModalTarget?.replace('#', '') || 'deleteStudent'}`}
        onConfirm={() => onModalConfirm()}
      />} />
    </>
  )
}

export const DataTable = withRouterAndRef<IDataTableProps>(Table);
