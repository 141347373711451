import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import configureCache from './cache';
import { environments } from '../../environments';
import { createUploadLink } from 'apollo-upload-client';
// import { offsetLimitPagination } from '@apollo/client/utilities/policies/pagination';
// import { queries } from '@testing-library/dom';
// import { QueryManager } from '@apollo/client/core/QueryManager';
// import { QueryInfo } from '@apollo/client/core/QueryInfo';
// import { paginationFieldPolicy } from '../utils/functions';
// import { Profesor } from '../models/interfaces';
// import { offsetLimitPagination } from '@apollo/client/utilities';

const httpLink = createUploadLink({
  uri: environments.graphql,
  headers: {}
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(environments.secureToken);
  if (!token) {
    return {
      headers: {
        ...headers,
      }
    }
  }
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const link = authLink.concat(httpLink);

const cache = new InMemoryCache(
  // {
  //   typePolicies: {
  //     StudentConnection: {
  //       fields: {
  //         // profesorsConnection:{ //offsetLimitPagination(),
  //         values:  //offsetLimitPagination()    //paginationFieldPolicy<Profesor>()
  //               {
  //               keyArgs: [],           
  //               merge(existing=[], incoming,{ args:{start=0}={}}) {
  //                 const merged = existing ? existing.slice(0) : [];
  //                 // console.log(start);
  //                 // console.log(existing);
  //                 // console.log(incoming);
                  
  //                 for (let i = 0; i < incoming.length; ++i) {
  //                   merged[start + i] = incoming[i];
  //                 }
                
  //                 return merged;
  //               },
  //               read(existing, {args}) {
  //                 return existing && existing.slice(args?.start, args?.offset + args?.limit);
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
)

const client = new ApolloClient({
  link,
  cache,
  connectToDevTools: true
});

configureCache(client);

export { client };