import React, { Component } from "react";
import './Header.css';
import { client } from "../../../../core/graphql";
import { CURRENT_USER } from "../../../../core/graphql/quering";
import { UsersPermissionsMe } from "../../../../core/models/interfaces";
import { environments } from "../../../../environments";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import { PHOTO_HEADER } from "../../../../core/graphql/quering/queries/students";
interface IState {
  scrollTop: number;
  user: UsersPermissionsMe;
  foto?: string
}

class _Header extends Component<RouteComponentProps, IState> {
  private _subs1: ZenObservable.Subscription;
  constructor(props) {
    super(props);
    this.state = {
      scrollTop: window.scrollY,
      user: undefined
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', () => this.setState({ scrollTop: window.scrollY }));
    // this._subs1 = client.watchQuery<{ currentUser: UsersPermissionsMe }>({ query: CURRENT_USER }).subscribe(res => {
    //   client.query({ query: PHOTO_STUDENT, variables: { username: res.data.currentUser.username } }).then(pht => {

    //     this.setState({
    //       ...this.state,
    //       foto: environments.serverUrl + pht.data.students[0].photo.url.substr(1)
    //     });
    //   }).catch(err => console.log(err)
    //   )
    //   this.setState({ user: res.data.currentUser })
    // })

    client.query({ query: CURRENT_USER })
      .then(res => {

        client.query({ query: PHOTO_HEADER, variables: { username: res.data.currentUser.username } })
          .then(pht => {
            if (pht.data.profesors.length) {
              this.setState({
                ...this.state,
                foto: environments.serverUrl + pht.data.profesors[0].photo.url.substr(1)
              });
            }
            else if (pht.data.students.length) {
              this.setState({
                ...this.state,
                foto: environments.serverUrl + pht.data.students[0].photo.url.substr(1)
              });
            }
          }).catch(err => console.log(err)
          )
        this.setState({ user: res.data.currentUser })
      })
      .catch(err => {
        console.log(err)
      })
  }

  componentWillUnmount() {
    if (this._subs1) {
      this._subs1.unsubscribe();
    }
  }

  onLogout(ev) {
    ev.preventDefault();
    localStorage.removeItem(environments.secureToken)
    localStorage.removeItem(environments.userInfo)
    client.writeQuery({
      query: CURRENT_USER,
      data: {
        currentUser: null
      }
    })
    this.props.history.push('/')
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-absolute fixed-top pr-0 py-0">
        <div className="container-fluid py-3">
          <div className="navbar-wrapper d-block">
            {/* <a className="navbar-brand" href="#!">Dashboard</a> */}
            <h1 className="h3 my-0 header-font" style={{ textTransform: 'uppercase' }}><strong>Sistema de Gestión Académica</strong></h1>
            <h2 className="h4 my-0 d-none d-sm-block header-font"><strong>Seminario Teológico de la Alianza Cristiana y Misionera de Cuba</strong></h2>
            {/* <h1 className="header-font">LAksd</h1> */}
          </div>
          <button className="navbar-toggler transparent" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
            <span className="sr-only">Toggle navigation</span>
            <span className="navbar-toggler-icon icon-bar"></span>
            <span className="navbar-toggler-icon icon-bar"></span>
            <span className="navbar-toggler-icon icon-bar"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" style={{zIndex: 100 }}>
            <ul className="navbar-nav">
              {/* <li className="nav-item dropdown">
                <a className="nav-link" href="http://example.com" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="material-icons">notifications</i>
                  <span className="notification">5</span>
                  <p className="d-lg-none d-md-block">
                    Some Actions
                  </p>
                </a>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                  <a className="dropdown-item" href="#!">Lorem ipsum dolor sit amet consectetur.</a>
                  <a className="dropdown-item" href="#!">Lorem ipsum dolor sit amet consectetur.</a>
                  <a className="dropdown-item" href="#!">Lorem ipsum dolor sit amet consectetur.</a>
                  <a className="dropdown-item" href="#!">Lorem ipsum dolor sit amet consectetur.</a>
                  <a className="dropdown-item" href="#!">Lorem ipsum dolor sit amet consectetur.</a>
                </div>
              </li> */}
              <li className="nav-item dropdown">
                <a href="#!" className="link" id="navbarDropdownProfile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {/* <i className="material-icons">person</i> */}
                    <img className="img rounded-circle" alt="Perfil" src={this.state.foto || '/assets/img/faces/default.png'} style={{ width: "45px", height: "45px" }} />                  
                  <p className="d-lg-none d-md-block">
                    Account
                  </p>
                </a>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownProfile">
                  <div className="dropdown-header text-center">{this.state?.user?.username}</div>
                  <Link to="/profile" className="dropdown-item">Perfil</Link>
                  {/* <a className="dropdown-item" href="#!">Settings</a> */}
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="#!" id="logout-btn" onClick={ev => this.onLogout(ev)}>Cerrar sesión</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export const Header = withRouter(_Header);