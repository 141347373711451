import React, { Component, createRef } from 'react';
import { Autocomplete } from 'react-materialize';
import "./modals.css";

interface IProps {
  title?: string;
  cancelLabel?: string;
  confirmLabel?: string;
  content?: string;
  id: string;
  onConfirm?: Function;
  placeholder?: string;
  options?: { value: any, label: string }[];
  value?: any;
  multiple?: boolean;
}

interface IState {
  selectedId: any;
  selectedLabel: string;
  value?: any;
  options: { value: any, label: string }[]
}

export class SelectModal extends Component<IProps, IState> {

  // inputRef = createRef<HTMLInputElement>();
  selectRef = createRef<HTMLSelectElement>();

  constructor(props) {
    super(props);
    this.state = {
      selectedId: null,
      selectedLabel: '',
      value: this.props.value,
      options: this.props.options
    }    
  }
//  componentWillMount(){
   
//   this.setState({
//     ...this.state,
//     options: this.props.options
//   })
//  }

  componentDidUpdate() {
   //M.Autocomplete.init(this.inputRef.current);
   M.FormSelect.init(this.selectRef.current); 
  }

  UNSAFE_componentWillReceiveProps(props: IProps) {
   this.setState({
      ...this.state,
      options: props.options,
      value: props.value
    });
  }

  onChangeInput(value, ev?: any){
   this.selectRef.current.selectedIndex = 0;       
    const selectedId = [this.props?.options?.find(opt => opt.label === value)?.value];

    this.setState({
      ...this.state,
      selectedId,
      selectedLabel: value,
      value
    })
  }

  onChange(value, ev?: any) {

    this.setState({
      ...this.state,
      value: ''    
    })

    if (this.props.multiple) {
      //console.log(value, ev.target.options);
      
      const select = ev.target as HTMLSelectElement;
      
      const values = [];
      for (let i = 0; i < select ?.options ?.length || 0; i++) {
        const option = select.options[i];
        if (option.selected) {
          values.push(option.value);
        }
      }
      let selectedLabel = '';
      values.forEach(val => {
        const label = this.props ?.options ?.find(opt => opt.value === val) ?.label;
        if (selectedLabel) {
          selectedLabel += `; ${label}`;
        } else {
          selectedLabel = label;
        }
      })
      
      this.setState({ selectedId: values, selectedLabel })
    } else {            
      const selectedLabel = this.props ?.options ?.find(opt => opt.value === value) ?.label;
      this.setState({ selectedId: [value], selectedLabel })
    }
  }

  onConfirm() {
    if (this.state?.selectedId && this.props.onConfirm) {
      this.props.onConfirm(this.state.selectedId, this.state.selectedLabel)
    }
  }

  render() {
    let data = {};
    this.state.options?.map(opt => data[opt.label] = null);
    // let options = this.state.options?.map((opt, i) => (<option key={i} value={opt.value}>{opt.label}</option>))
    return (
      <>
        <div className="modal fade" id={this.props.id} tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{this.props.title || 'Title'}</h5>
                <button type="button" className="close transparent" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {this.props.content}
                <>
                  <Autocomplete
                    // icon={<Icon>search</Icon>} 
                    id={this?.props?.id || this.state.value}                  
                    onChange={ev=>this.onChangeInput(ev.target.value, ev)}
                    options={{
                      onAutocomplete: (value)=>this.onChangeInput(value),
                      data: data
                    }}
                    value={this.state.value}
                    placeholder="Escriba para filtrar la busqueda"
                    title="Busqueda avanzada"
                  />
                </>
                     
                {!this.props?.multiple && <select defaultValue={this.state?.value || -1} ref={this.selectRef} onChange={ev => this.onChange(ev.target.value, ev)}>
                  <option value="-1" disabled>Selecione</option>
                  {/* Aqui probar usando el estado y no las props */}
                  {
                  this.state.options?.map((opt, i) => (<option key={i} value={opt.value}>{opt.label}</option>))
          
                  }
                  </select>}
     
                {this.props?.multiple && <select multiple defaultValue={[]} ref={this.selectRef} onChange={ev => this.onChange(ev.target.value, ev)}>
                  <option value="-1" disabled>Seleccione las opciones</option>
                  {this.state.options?.map((opt, i) => (<option key={i} value={opt.value}>{opt.label}</option>))}
                </select>}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">{this.props.cancelLabel || 'Cancelar'}</button>
                <button type="button" onClick={() => this.onConfirm()}
                  className={`btn btn-primary ${this.state?.selectedId ? '' : 'disabled'}`} data-dismiss= "modal">{this.props.confirmLabel || 'Confirmar'}</button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}