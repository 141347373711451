import { GraphQLError } from "graphql";

export interface AdminUser {
  id: string;
  username?: string;
}
export enum CacheControlScope {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}
export interface Class {
  id: string;
  created_at: Date;
  updated_at: Date;
  startDate: Date;
  finishDate: Date;
  credits?: number;
  facetoface?: boolean;
  eventsState?: JSON;
  state?: boolean;
  notes?: string;
  tutor?: Profesor;
  asignatura?: Subject;
  profesor?: Profesor;
  aula?: Classroom;
  level?: ENUM_CLASS_LEVEL;
  area?: ENUM_CLASS_AREA;
  cathegory?: ENUM_CLASS_CATHEGORY;
  created_by?: AdminUser;
  updated_by?: AdminUser;
  recurso?: Resource[]; //Esto no era opcional sino obligatorio al igual que estudiante
  estudiante?: Student[];
}
export interface ClassAggregator {
  count?: number;
  totalCount?: number;
  sum?: ClassAggregatorSum;
  avg?: ClassAggregatorAvg;
  min?: ClassAggregatorMin;
  max?: ClassAggregatorMax;
}
export interface ClassAggregatorAvg {
  credits?: number;
}
export interface ClassAggregatorMax {
  credits?: number;
}
export interface ClassAggregatorMin {
  credits?: number;
}
export interface ClassAggregatorSum {
  credits?: number;
}
export interface ClassConnection {
  values?: Class[];
  groupBy?: ClassGroupBy;
  aggregate?: ClassAggregator;
}
export interface ClassConnectionArea {
  key?: string;
  connection?: ClassConnection;
}
export interface ClassConnectionAsignatura {
  key?: string;
  connection?: ClassConnection;
}
export interface ClassConnectionAula {
  key?: string;
  connection?: ClassConnection;
}
export interface ClassConnectionCathegory {
  key?: string;
  connection?: ClassConnection;
}
export interface ClassConnectionCreated_at {
  key?: Date;
  connection?: ClassConnection;
}
export interface ClassConnectionCreated_by {
  key?: string;
  connection?: ClassConnection;
}
export interface ClassConnectionCredits {
  key?: number;
  connection?: ClassConnection;
}
export interface ClassConnectionEventsState {
  key?: JSON;
  connection?: ClassConnection;
}
export interface ClassConnectionFacetoface {
  key?: boolean;
  connection?: ClassConnection;
}
export interface ClassConnectionFinishDate {
  key?: string;
  connection?: ClassConnection;
}
export interface ClassConnectionId {
  key?: string;
  connection?: ClassConnection;
}
export interface ClassConnectionLevel {
  key?: string;
  connection?: ClassConnection;
}
export interface ClassConnectionNotes {
  key?: string;
  connection?: ClassConnection;
}
export interface ClassConnectionProfesor {
  key?: string;
  connection?: ClassConnection;
}
export interface ClassConnectionStartDate {
  key?: string;
  connection?: ClassConnection;
}
export interface ClassConnectionState {
  key?: boolean;
  connection?: ClassConnection;
}
export interface ClassConnectionTutor {
  key?: string;
  connection?: ClassConnection;
}
export interface ClassConnectionUpdated_at {
  key?: Date;
  connection?: ClassConnection;
}
export interface ClassConnectionUpdated_by {
  key?: string;
  connection?: ClassConnection;
}
export interface ClassGroupBy {
  id?: ClassConnectionId[];
  created_at?: ClassConnectionCreated_at[];
  updated_at?: ClassConnectionUpdated_at[];
  startDate?: ClassConnectionStartDate[];
  finishDate?: ClassConnectionFinishDate[];
  credits?: ClassConnectionCredits[];
  facetoface?: ClassConnectionFacetoface[];
  eventsState?: ClassConnectionEventsState[];
  state?: ClassConnectionState[];
  notes?: ClassConnectionNotes[];
  tutor?: ClassConnectionTutor[];
  asignatura?: ClassConnectionAsignatura[];
  profesor?: ClassConnectionProfesor[];
  aula?: ClassConnectionAula[];
  level?: ClassConnectionLevel[];
  area?: ClassConnectionArea[];
  cathegory?: ClassConnectionCathegory[];
  created_by?: ClassConnectionCreated_by[];
  updated_by?: ClassConnectionUpdated_by[];
}
export interface ClassInput {
  startDate: Date
  finishDate: Date
  credits?: number;
  facetoface?: boolean;
  eventsState?: JSON;
  state?: boolean;
  notes?: string;
  tutor?: string;
  asignatura?: string;
  recurso?: (string | number)[];
  profesor?: string;
  aula?: string;
  level?: ENUM_CLASS_LEVEL;
  area?: ENUM_CLASS_AREA;
  cathegory?: ENUM_CLASS_CATHEGORY;
  estudiante?: (string | number)[];
  created_by?: string;
  updated_by?: string;
}
export interface Classroom {
  id: string;
  created_at: Date;
  updated_at: Date;
  localname: string;
  address?: string;
  capacity?: number;
  notes?: string;
  created_by?: AdminUser;
  updated_by?: AdminUser;
  clase: Class[];
  estudiantes: Student[];
}
export interface ClassroomAggregator {
  count?: number;
  totalCount?: number;
  sum?: ClassroomAggregatorSum;
  avg?: ClassroomAggregatorAvg;
  min?: ClassroomAggregatorMin;
  max?: ClassroomAggregatorMax;
}
export interface ClassroomAggregatorAvg {
  capacity?: number;
}
export interface ClassroomAggregatorMax {
  capacity?: number;
}
export interface ClassroomAggregatorMin {
  capacity?: number;
}
export interface ClassroomAggregatorSum {
  capacity?: number;
}
export interface ClassroomConnection {
  values?: Classroom[];
  groupBy?: ClassroomGroupBy;
  aggregate?: ClassroomAggregator;
}
export interface ClassroomConnectionAddress {
  key?: string;
  connection?: ClassroomConnection;
}
export interface ClassroomConnectionCapacity {
  key?: number;
  connection?: ClassroomConnection;
}
export interface ClassroomConnectionCreated_at {
  key?: Date;
  connection?: ClassroomConnection;
}
export interface ClassroomConnectionCreated_by {
  key?: string;
  connection?: ClassroomConnection;
}
export interface ClassroomConnectionId {
  key?: string;
  connection?: ClassroomConnection;
}
export interface ClassroomConnectionLocalname {
  key?: string;
  connection?: ClassroomConnection;
}
export interface ClassroomConnectionNotes {
  key?: string;
  connection?: ClassroomConnection;
}
export interface ClassroomConnectionUpdated_at {
  key?: Date;
  connection?: ClassroomConnection;
}
export interface ClassroomConnectionUpdated_by {
  key?: string;
  connection?: ClassroomConnection;
}
export interface ClassroomGroupBy {
  id?: ClassroomConnectionId[];
  created_at?: ClassroomConnectionCreated_at[];
  updated_at?: ClassroomConnectionUpdated_at[];
  localname?: ClassroomConnectionLocalname[];
  address?: ClassroomConnectionAddress[];
  capacity?: ClassroomConnectionCapacity[];
  notes?: ClassroomConnectionNotes[];
  created_by?: ClassroomConnectionCreated_by[];
  updated_by?: ClassroomConnectionUpdated_by[];
}
export interface ClassroomInput {
  localname: string;
  address?: string;
  capacity?: number;
  notes?: string;
  clase?: (string | number)[];
  estudiantes?: (string | number)[];
  created_by?: string;
  updated_by?: string;
}
export interface createClassInput {
  data?: ClassInput;
}
export interface createClassPayload {
  class?: Class;
}
export interface createClassroomInput {
  data?: ClassroomInput;
}
export interface createClassroomPayload {
  classroom?: Classroom;
}
export interface createDepartmentInput {
  data?: DepartmentInput;
}
export interface createDepartmentPayload {
  department?: Department;
}
export interface createProfesorInput {
  data?: ProfesorInput;
}
export interface createProfesorPayload {
  profesor?: Profesor;
}
export interface createResourceInput {
  data?: ResourceInput;
}
export interface createResourcePayload {
  resource?: Resource;
}
export interface createRoleInput {
  data?: RoleInput;
}
export interface createRolePayload {
  role?: UsersPermissionsRole;
}
export interface createStudentInput {
  data?: StudentInput;
}
export interface createStudentPayload {
  student?: Student;
}
export interface createSubjectInput {
  data?: SubjectInput;
}
export interface createSubjectPayload {
  subject?: Subject;
}

export interface createUserInput {
  data?: UserInput;
}
export interface createUserPayload {
  user?: UsersPermissionsUser;
}
// A date string, such as 2007-12-03, compliant with the `full-date` format
// outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for
// representation of dates and times using the Gregorian calendar.export type Date = any;
// A date-time string at UTC, such as 2007-12-03T10?:15;?:30Z, compliant with the
// `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO
// 8601 standard for representation of dates and times using the Gregorian calendar.export type DateTime = any;
export interface deleteClassInput {
  where?: InputID;
}
export interface deleteClassPayload {
  class?: Class;
}
export interface deleteClassroomInput {
  where?: InputID;
}
export interface deleteClassroomPayload {
  classroom?: Classroom;
}
export interface deleteDepartmentInput {
  where?: InputID;
}
export interface deleteDepartmentPayload {
  department?: Department;
}
export interface deleteProfesorInput {
  where?: InputID;
}
export interface deleteProfesorPayload {
  profesor?: Profesor;
}
export interface deleteResourceInput {
  where?: InputID;
}
export interface deleteResourcePayload {
  resource?: Resource;
}
export interface deleteRoleInput {
  where?: InputID;
}
export interface deleteRolePayload {
  role?: UsersPermissionsRole;
}
export interface deleteStudentInput {
  where?: InputID;
}
export interface deleteStudentPayload {
  student?: Student;
}
export interface deleteSubjectInput {
  where?: InputID;
}
export interface deleteSubjectPayload {
  subject?: Subject;
}
export interface deleteUserInput {
  where?: InputID;
}
export interface deleteUserPayload {
  user?: UsersPermissionsUser;
}
export interface Department {
  id: string;
  created_at: Date;
  updated_at: Date;
  name?: string;
  notes?: string;
  profesor_jefe?: Profesor;
  created_by?: AdminUser;
  updated_by?: AdminUser;
  asignatura: Subject[];
  profesors: Profesor[];
}
export interface DepartmentAggregator {
  count?: number;
  totalCount?: number;
}
export interface DepartmentConnection {
  values?: Department[];
  groupBy?: DepartmentGroupBy;
  aggregate?: DepartmentAggregator;
}
export interface DepartmentConnectionCreated_at {
  key?: Date;
  connection?: DepartmentConnection;
}
export interface DepartmentConnectionCreated_by {
  key?: string;
  connection?: DepartmentConnection;
}
export interface DepartmentConnectionId {
  key?: string;
  connection?: DepartmentConnection;
}
export interface DepartmentConnectionName {
  key?: string;
  connection?: DepartmentConnection;
}
export interface DepartmentConnectionNotes {
  key?: string;
  connection?: DepartmentConnection;
}
export interface DepartmentConnectionProfesor_jefe {
  key?: string;
  connection?: DepartmentConnection;
}
export interface DepartmentConnectionUpdated_at {
  key?: Date;
  connection?: DepartmentConnection;
}
export interface DepartmentConnectionUpdated_by {
  key?: string;
  connection?: DepartmentConnection;
}
export interface DepartmentGroupBy {
  id?: DepartmentConnectionId[];
  created_at?: DepartmentConnectionCreated_at[];
  updated_at?: DepartmentConnectionUpdated_at[];
  name?: DepartmentConnectionName[];
  notes?: DepartmentConnectionNotes[];
  profesor_jefe?: DepartmentConnectionProfesor_jefe[];
  created_by?: DepartmentConnectionCreated_by[];
  updated_by?: DepartmentConnectionUpdated_by[];
}
export interface DepartmentInput {
  name?: string;
  asignatura?: (string | number)[];
  notes?: string;
  profesor_jefe?: string;
  profesors?: (string | number)[];
  created_by?: string;
  updated_by?: string;
}
export interface editClassInput {
  startDate?: Date
  finishDate?: Date
  credits?: number;
  facetoface?: boolean;
  eventsState?: JSON;
  state?: boolean;
  notes?: string;
  tutor?: string;
  asignatura?: string;
  recurso?: (string | number)[];
  profesor?: string;
  aula?: string;
  level?: ENUM_CLASS_LEVEL;
  area?: ENUM_CLASS_AREA;
  cathegory?: ENUM_CLASS_CATHEGORY;
  estudiante?: (string | number)[];
  created_by?: string;
  updated_by?: string;
}
export interface editClassroomInput {
  localname?: string;
  address?: string;
  capacity?: number;
  notes?: string;
  clase?: (string | number)[];
  estudiantes?: (string | number)[];
  created_by?: string;
  updated_by?: string;
}
export interface editDepartmentInput {
  name?: string;
  asignatura?: (string | number)[];
  notes?: string;
  profesor_jefe?: string;
  profesors?: (string | number)[];
  created_by?: string;
  updated_by?: string;
}
export interface editFileInput {
  name?: string;
  alternativeText?: string;
  caption?: string;
  width?: number;
  height?: number;
  formats?: JSON;
  hash?: string;
  ext?: string;
  mime?: string;
  size?: number;
  url?: string;
  previewUrl?: string;
  provider?: string;
  provider_metadata?: JSON;
  related?: (string | number)[];
  created_by?: string;
  updated_by?: string;
}
export interface editProfesorInput {
  name?: string;
  lastname?: string;
  country?: string;
  email?: string;
  notes?: string;
  institution?: string;
  active?: boolean;
  eventsActive?: JSON;
  username?: string;
  clases?: (string | number)[];
  phone?: string;
  title?: ENUM_PROFESOR_TITLE;
  photo?: string;
  departamentos?: string[];
  created_by?: string;
  updated_by?: string;
}
export interface editResourceInput {
  name?: string;
  author?: string;
  clase?: string;
  description?: string;
  files?: (string | number)[];
  created_by?: string;
  updated_by?: string;
}
export interface editRoleInput {
  name?: string;
  description?: string;
  type?: string;
  permissions?: (string | number)[];
  users?: (string | number)[];
  created_by?: string;
  updated_by?: string;
}
export interface editStudentInput {
  name?: string;
  lastname?: string;
  municipality?: string;
  province?: string;
  neighborhood?: string;
  churchAddress?: string;
  studentAddress?: string;
  otherTitles?: JSON;
  secularJob?: string;
  email?: string;
  phone?: number;
  mobile?: number;
  ci?: Long;
  church?: string;
  ministry?: string;
  califications?: JSON;
  active?: boolean;
  eventsActive?: JSON;
  devices?: JSON;
  username?: string;
  clase?: (string | number)[];
  notes?: string;
  zone?: number;
  academyGrade?: number;
  currentLevel?: ENUM_STUDENT_CURRENTLEVEL;
  levelFinished?: ENUM_STUDENT_LEVELFINISHED;
  aula?: string;
  photo?: string;
  ciPhoto?: string;
  ciPhoto2?:string;
  created_by?: string;
  updated_by?: string;
}
export interface editSubjectInput {
  name?: string;
  notes?: string;
  clase?: (string | number)[];
  departamento?: string;
  obsoleta?: boolean;
  created_by?: string;
  updated_by?: string;
}
export interface editUserInput {
  username?: string;
  email?: string;
  provider?: string;
  password?: string;
  resetPasswordToken?: string;
  confirmed?: boolean;
  blocked?: boolean;
  role?: string;
  created_by?: string;
  updated_by?: string;
}

export interface event {
  state?: string;
  motive?: string;
  date?: any;
}
export enum ENUM_CLASS_AREA {
  TEOLOGICA = 'TEOLOGICA',
  MINISTERIAL = 'MINISTERIAL',
  ESPIRITUAL = 'ESPIRITUAL',
  BIBLICA = 'BIBLICA',
}
export enum ENUM_CLASS_CATHEGORY {
  CLASE = 'CLASE',
  TALLER = 'TALLER',
  SEMINARIO = 'SEMINARIO',
  PRACTICA = 'PRACTICA',
  PREINST = 'PREINST',
  TRANSFER = 'TRANSFER',
  COMBALIDADA = 'COMBALIDADA',
}
export enum ENUM_CLASS_LEVEL {
  INCAMI1 = 'INCAMI1',
  INCAMI2 = 'INCAMI2',
  SEMTACM = 'SEMTACM',
  MAESTRIA = 'MAESTRIA',
  DOCTORADO = 'DOCTORADO',
  DIPLOMADO = 'DIPLOMADO',
}
export enum ENUM_PROFESOR_TITLE {
  Lic = 'Lic',
  LicDip = 'LicDip',
  Mtr = 'Mtr',
  Mrc = 'Mrc',
  Dr = 'Dr',
  Drc = 'Drc',
}
export enum ENUM_STUDENT_ROLE{
  ROLE_STUDENT = "ROLE_STUDENT",
  ROLE_PROFESOR = "ROLE_PROFESOR",
  // ROLE_PUBLIC = "ROLE_PUBLIC",
  // ROLE_AUTHENTICATED = "AUTHENTICATED",
  ROLE_ADMIN = "ROLE_ADMIN"
}
export enum ENUM_STUDENT_CURRENTLEVEL {
  INCAMI1 = 'INCAMI1',
  INCAMI2 = 'INCAMI2',
  SEMTACM = 'SEMTACM',
  MAESTRIA = 'MAESTRIA',
  DOCTORADO = 'DOCTORADO',
}
export enum ENUM_STUDENT_LEVELFINISHED {
  INCAMI1 = 'INCAMI1',
  INCAMI2 = 'INCAMI2',
  SEMTACM = 'SEMTACM',
  MAESTRIA = 'MAESTRIA',
  DOCTORADO = 'DOCTORADO',
}

// NO_CERTIFICADO
// PRIMARIA
// SECUNDARIA
// BACHILLER
// UNIVERSITARIO
// MAESTRIA
// DOCTORADO

export const ENUM_STUDENT_ACADEMYGRADE = {
  1:'NO_CERTIFICADO',
  2:'PRIMARIA',
  3: 'SECUNDARIA',
  4: 'BACHILLER',
  5: 'UNIVERSITARIO',
  6: 'MASTER',
  7: 'DOCTOR'
}

// export enum ENUM_STUDENT_ACADEMYGRADE {
//   NO_CERTIFICADO = "1", //'NO_CERTIFICADO',
//   PRIMARIA = "2", //'PRIMARIA',
//   SECUNDARIA = "3", //'SECUNDARIA',
//   BACHILLER = "4", //'BACHILLER',
//   UNIVERSITARIO = "5", //'INGENIERO',
//   MAESTRIA = "6", //'MAESTRIA',
//   DOCTORADO = "7", //'DOCTORADO',
// }

export interface FileInput {
  name: string;
  alternativeText?: string;
  caption?: string;
  width?: number;
  height?: number;
  formats?: JSON;
  hash: string;
  ext?: string;
  mime: string;
  size: number;
  url: string;
  previewUrl?: string;
  provider: string;
  provider_metadata?: JSON;
  related?: (string | number)[];
  created_by?: string;
  updated_by?: string;
}
export interface InputID {
  id: string;
}
// The `JSON`export type export interface  represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). = any;
export type JSON = any;
// The `Long`export type export interface  represents 52-bit integers = any;
export type Long = any;
export type Morph =
  UsersPermissionsMe
  | UsersPermissionsMeRole
  | UsersPermissionsLoginPayload
  | UserPermissionsPasswordPayload
  | Class
  | ClassConnection
  | ClassAggregator
  | ClassAggregatorSum
  | ClassAggregatorAvg
  | ClassAggregatorMin
  | ClassAggregatorMax
  | ClassGroupBy
  | ClassConnectionId
  | ClassConnectionCreated_at
  | ClassConnectionUpdated_at
  | ClassConnectionStartDate
  | ClassConnectionFinishDate
  | ClassConnectionCredits
  | ClassConnectionFacetoface
  | ClassConnectionEventsState
  | ClassConnectionState
  | ClassConnectionNotes
  | ClassConnectionTutor
  | ClassConnectionAsignatura
  | ClassConnectionProfesor
  | ClassConnectionAula
  | ClassConnectionLevel
  | ClassConnectionArea
  | ClassConnectionCathegory
  | ClassConnectionCreated_by
  | ClassConnectionUpdated_by
  | createClassPayload
  | updateClassPayload
  | deleteClassPayload
  | Classroom
  | ClassroomConnection
  | ClassroomAggregator
  | ClassroomAggregatorSum
  | ClassroomAggregatorAvg
  | ClassroomAggregatorMin
  | ClassroomAggregatorMax
  | ClassroomGroupBy
  | ClassroomConnectionId
  | ClassroomConnectionCreated_at
  | ClassroomConnectionUpdated_at
  | ClassroomConnectionLocalname
  | ClassroomConnectionAddress
  | ClassroomConnectionCapacity
  | ClassroomConnectionNotes
  | ClassroomConnectionCreated_by
  | ClassroomConnectionUpdated_by
  | createClassroomPayload
  | updateClassroomPayload
  | deleteClassroomPayload
  | Department
  | DepartmentConnection
  | DepartmentAggregator
  | DepartmentGroupBy
  | DepartmentConnectionId
  | DepartmentConnectionCreated_at
  | DepartmentConnectionUpdated_at
  | DepartmentConnectionName
  | DepartmentConnectionNotes
  | DepartmentConnectionProfesor_jefe
  | DepartmentConnectionCreated_by
  | DepartmentConnectionUpdated_by
  | createDepartmentPayload
  | updateDepartmentPayload
  | deleteDepartmentPayload
  | Profesor
  | ProfesorConnection
  | ProfesorAggregator
  | ProfesorGroupBy
  | ProfesorConnectionId
  | ProfesorConnectionCreated_at
  | ProfesorConnectionUpdated_at
  | ProfesorConnectionName
  | ProfesorConnectionLastname
  | ProfesorConnectionCountry
  | ProfesorConnectionEmail
  | ProfesorConnectionNotes
  | ProfesorConnectionInstitution
  | ProfesorConnectionActive
  | ProfesorConnectionEventsActive
  | ProfesorConnectionUsername
  | ProfesorConnectionPhone
  | ProfesorConnectionTitle
  | ProfesorConnectionPhoto
  | ProfesorConnectionDepartamento
  | ProfesorConnectionCreated_by
  | ProfesorConnectionUpdated_by
  | createProfesorPayload
  | updateProfesorPayload
  | deleteProfesorPayload
  | Resource
  | ResourceConnection
  | ResourceAggregator
  | ResourceGroupBy
  | ResourceConnectionId
  | ResourceConnectionCreated_at
  | ResourceConnectionUpdated_at
  | ResourceConnectionName
  | ResourceConnectionAuthor
  | ResourceConnectionClase
  | ResourceConnectionDescription
  | ResourceConnectionCreated_by
  | ResourceConnectionUpdated_by
  | createResourcePayload
  | updateResourcePayload
  | deleteResourcePayload
  | Student
  | StudentConnection
  | StudentAggregator
  | StudentAggregatorSum
  | StudentAggregatorAvg
  | StudentAggregatorMin
  | StudentAggregatorMax
  | StudentGroupBy
  | StudentConnectionId
  | StudentConnectionCreated_at
  | StudentConnectionUpdated_at
  | StudentConnectionName
  | StudentConnectionLastname
  | StudentConnectionMunicipality
  | StudentConnectionProvince
  | StudentConnectionNeighborhood
  | StudentConnectionChurchAddress
  | StudentConnectionStudentAddress
  | StudentConnectionOtherTitles
  | StudentConnectionSecularJob
  | StudentConnectionEmail
  | StudentConnectionPhone
  | StudentConnectionMobile
  | StudentConnectionCi
  | StudentConnectionChurch
  | StudentConnectionMinistry
  | StudentConnectionCalifications
  | StudentConnectionPhoto
  | StudentConnectionActive
  | StudentConnectionEventsActive
  | StudentConnectionDevices
  | StudentConnectionUsername
  | StudentConnectionNotes
  | StudentConnectionZone
  | StudentConnectionAcademyGrade
  | StudentConnectionCurrentLevel
  | StudentConnectionLevelFinished
  | StudentConnectionAula
  | StudentConnectionCiPhoto
  | StudentConnectionCreated_by
  | StudentConnectionUpdated_by
  | createStudentPayload
  | updateStudentPayload
  | deleteStudentPayload
  | Subject
  | SubjectConnection
  | SubjectAggregator
  | SubjectGroupBy
  | SubjectConnectionId
  | SubjectConnectionCreated_at
  | SubjectConnectionUpdated_at
  | SubjectConnectionName
  | SubjectConnectionNotes
  | SubjectConnectionDepartamento
  | SubjectConnectionObsoleta
  | SubjectConnectionCreated_by
  | SubjectConnectionUpdated_by
  | createSubjectPayload
  | updateSubjectPayload
  | deleteSubjectPayload
  | UploadFile
  | UploadFileConnection
  | UploadFileAggregator
  | UploadFileAggregatorSum
  | UploadFileAggregatorAvg
  | UploadFileAggregatorMin
  | UploadFileAggregatorMax
  | UploadFileGroupBy
  | UploadFileConnectionId
  | UploadFileConnectionCreated_at
  | UploadFileConnectionUpdated_at
  | UploadFileConnectionName
  | UploadFileConnectionAlternativeText
  | UploadFileConnectionCaption
  | UploadFileConnectionWidth
  | UploadFileConnectionHeight
  | UploadFileConnectionFormats
  | UploadFileConnectionHash
  | UploadFileConnectionExt
  | UploadFileConnectionMime
  | UploadFileConnectionSize
  | UploadFileConnectionUrl
  | UploadFileConnectionPreviewUrl
  | UploadFileConnectionProvider
  | UploadFileConnectionProvider_metadata
  | UploadFileConnectionCreated_by
  | UploadFileConnectionUpdated_by
  | UsersPermissionsPermission
  | UsersPermissionsRole
  | UsersPermissionsRoleConnection
  | UsersPermissionsRoleAggregator
  | UsersPermissionsRoleGroupBy
  | UsersPermissionsRoleConnectionId
  | UsersPermissionsRoleConnectionName
  | UsersPermissionsRoleConnectionDescription
  | UsersPermissionsRoleConnectionType
  | UsersPermissionsRoleConnectionCreated_by
  | UsersPermissionsRoleConnectionUpdated_by
  | createRolePayload
  | updateRolePayload
  | deleteRolePayload
  | UsersPermissionsUser
  | UsersPermissionsUserConnection
  | UsersPermissionsUserAggregator
  | UsersPermissionsUserGroupBy
  | UsersPermissionsUserConnectionId
  | UsersPermissionsUserConnectionCreated_at
  | UsersPermissionsUserConnectionUpdated_at
  | UsersPermissionsUserConnectionUsername
  | UsersPermissionsUserConnectionEmail
  | UsersPermissionsUserConnectionProvider
  | UsersPermissionsUserConnectionConfirmed
  | UsersPermissionsUserConnectionBlocked
  | UsersPermissionsUserConnectionRole
  | UsersPermissionsUserConnectionCreated_by
  | UsersPermissionsUserConnectionUpdated_by
  | createUserPayload
  | updateUserPayload
  | deleteUserPayload
export interface Mutation {
  createClass(input?: createClassInput): createClassPayload;
  updateClass(input?: updateClassInput): updateClassPayload;
  deleteClass(input?: deleteClassInput): deleteClassPayload;
  createClassroom(input?: createClassroomInput): createClassroomPayload;
  updateClassroom(input?: updateClassroomInput): updateClassroomPayload;
  deleteClassroom(input?: deleteClassroomInput): deleteClassroomPayload;
  createDepartment(input?: createDepartmentInput): createDepartmentPayload;
  updateDepartment(input?: updateDepartmentInput): updateDepartmentPayload;
  deleteDepartment(input?: deleteDepartmentInput): deleteDepartmentPayload;
  createProfesor(input?: createProfesorInput): createProfesorPayload;
  updateProfesor(input?: updateProfesorInput): updateProfesorPayload;
  deleteProfesor(input?: deleteProfesorInput): deleteProfesorPayload;
  createResource(input?: createResourceInput): createResourcePayload;
  updateResource(input?: updateResourceInput): updateResourcePayload;
  deleteResource(input?: deleteResourceInput): deleteResourcePayload;
  createStudent(input?: createStudentInput): createStudentPayload;
  updateStudent(input?: updateStudentInput): updateStudentPayload;
  deleteStudent(input?: deleteStudentInput): deleteStudentPayload;
  createSubject(input?: createSubjectInput): createSubjectPayload;
  updateSubject(input?: updateSubjectInput): updateSubjectPayload;
  deleteSubject(input?: deleteSubjectInput): deleteSubjectPayload;
  // Create a new role
  createRole(input?: createRoleInput): createRolePayload;
  // Update an existing role                                     
  updateRole(input?: updateRoleInput): updateRolePayload;
  // Delete an existing role
  deleteRole(input?: deleteRoleInput): deleteRolePayload;
  // Create a new user
  createUser(input?: createUserInput): createUserPayload;
  // Update an existing user         
  updateUser(input?: updateUserInput): updateUserPayload;
  // Delete an existing user           
  deleteUser(input?: deleteUserInput): deleteUserPayload;
  
  multipleUpload(
    refId?: string,
    ref?: string,
    field?: string,
    source?: string,
    files?: Upload[],
  ): UploadFile[];
  login(input: UsersPermissionsLoginInput): UsersPermissionsLoginPayload;
  register(input: UsersPermissionsRegisterInput): UsersPermissionsLoginPayload;
  forgotPassword(email: string): UserPermissionsPasswordPayload;
  resetPassword(
    password: string,
    passwordConfirmation: string,
    code: string,
  ): UsersPermissionsLoginPayload;
  emailConfirmation(confirmation: string): UsersPermissionsLoginPayload;
}
export interface Profesor {
  id: string;
  created_at: Date;
  updated_at: Date;
  name: string;
  lastname: string;
  country: string;
  email?: string;
  notes?: string;
  institution?: string;
  active?: boolean;
  eventsActive?: JSON;
  username?: string;
  phone?: string;
  title?: ENUM_PROFESOR_TITLE;
  photo?: UploadFile;
  departamentos?: Department[];
  created_by?: AdminUser;
  updated_by?: AdminUser;
  clases: Class[];
}
export interface ProfesorAggregator {
  count?: number;
  totalCount?: number;
}
export interface ProfesorConnection {
  values?: Profesor[];
  groupBy?: ProfesorGroupBy;
  aggregate?: ProfesorAggregator;
}
export interface ProfesorConnectionActive {
  key?: boolean;
  connection?: ProfesorConnection;
}
export interface ProfesorConnectionCountry {
  key?: string;
  connection?: ProfesorConnection;
}
export interface ProfesorConnectionCreated_at {
  key?: Date;
  connection?: ProfesorConnection;
}
export interface ProfesorConnectionCreated_by {
  key?: string;
  connection?: ProfesorConnection;
}
export interface ProfesorConnectionDepartamento {
  key?: string;
  connection?: ProfesorConnection;
}
export interface ProfesorConnectionEmail {
  key?: string;
  connection?: ProfesorConnection;
}
export interface ProfesorConnectionEventsActive {
  key?: JSON;
  connection?: ProfesorConnection;
}
export interface ProfesorConnectionId {
  key?: string;
  connection?: ProfesorConnection;
}
export interface ProfesorConnectionInstitution {
  key?: string;
  connection?: ProfesorConnection;
}
export interface ProfesorConnectionLastname {
  key?: string;
  connection?: ProfesorConnection;
}
export interface ProfesorConnectionName {
  key?: string;
  connection?: ProfesorConnection;
}
export interface ProfesorConnectionNotes {
  key?: string;
  connection?: ProfesorConnection;
}
export interface ProfesorConnectionPhone {
  key?: string;
  connection?: ProfesorConnection;
}
export interface ProfesorConnectionPhoto {
  key?: string;
  connection?: ProfesorConnection;
}
export interface ProfesorConnectionTitle {
  key?: string;
  connection?: ProfesorConnection;
}
export interface ProfesorConnectionUpdated_at {
  key?: Date;
  connection?: ProfesorConnection;
}
export interface ProfesorConnectionUpdated_by {
  key?: string;
  connection?: ProfesorConnection;
}
export interface ProfesorConnectionUsername {
  key?: string;
  connection?: ProfesorConnection;
}
export interface ProfesorGroupBy {
  id?: ProfesorConnectionId[];
  created_at?: ProfesorConnectionCreated_at[];
  updated_at?: ProfesorConnectionUpdated_at[];
  name?: ProfesorConnectionName[];
  lastname?: ProfesorConnectionLastname[];
  country?: ProfesorConnectionCountry[];
  email?: ProfesorConnectionEmail[];
  notes?: ProfesorConnectionNotes[];
  institution?: ProfesorConnectionInstitution[];
  active?: ProfesorConnectionActive[];
  eventsActive?: ProfesorConnectionEventsActive[];
  username?: ProfesorConnectionUsername[];
  phone?: ProfesorConnectionPhone[];
  title?: ProfesorConnectionTitle[];
  photo?: ProfesorConnectionPhoto[];
  departamento?: ProfesorConnectionDepartamento[];
  created_by?: ProfesorConnectionCreated_by[];
  updated_by?: ProfesorConnectionUpdated_by[];
}
export interface ProfesorInput {
  name: string;
  lastname: string;
  country: string;
  email?: string;
  notes?: string;
  institution?: string;
  active?: boolean;
  eventsActive?: JSON;
  username?: string;
  clases?: (string | number)[];
  phone?: string;
  title?: ENUM_PROFESOR_TITLE;
  photo?: string;
  departamento?: string;
  created_by?: string;
  updated_by?: string;
}
export interface Query {
  class(id: string): Class;
  classes(sort?: String, limit?: number, start?: number, where?: JSON): Class[];
  classesConnection(
    sort?: string,
    limit?: number,
    start?: number,
    where?: JSON,
  ): ClassConnection;
  classroom(id: string): Classroom;
  classrooms(sort?: String, limit?: number, start?: number, where?: JSON): Classroom[];
  classroomsConnection(
    sort?: string,
    limit?: number,
    start?: number,
    where?: JSON,
  ): ClassroomConnection;
  department(id: string): Department;
  departments(sort?: String, limit?: number, start?: number, where?: JSON): Department[];
  departmentsConnection(
    sort?: string,
    limit?: number,
    start?: number,
    where?: JSON,
  ): DepartmentConnection;
  profesor(id: string): Profesor;
  profesors(sort?: String, limit?: number, start?: number, where?: JSON): Profesor[];
  profesorsConnection(
    sort?: string,
    limit?: number,
    start?: number,
    where?: JSON,
  ): ProfesorConnection;
  resource(id: string): Resource;
  resources(sort?: String, limit?: number, start?: number, where?: JSON): Resource[];
  resourcesConnection(
    sort?: string,
    limit?: number,
    start?: number,
    where?: JSON,
  ): ResourceConnection;
  student(id: string): Student;
  students(sort?: String, limit?: number, start?: number, where?: JSON): Student[];
  studentsConnection(
    sort?: string,
    limit?: number,
    start?: number,
    where?: JSON,
  ): StudentConnection;
  subject(id: string): Subject;
  subjects(sort?: String, limit?: number, start?: number, where?: JSON): Subject[];
  subjectsConnection(
    sort?: string,
    limit?: number,
    start?: number,
    where?: JSON,
  ): SubjectConnection;
  files(sort?: String, limit?: number, start?: number, where?: JSON): UploadFile[];
  filesConnection(
    sort?: string,
    limit?: number,
    start?: number,
    where?: JSON,
  ): UploadFileConnection;
  role(id: string): UsersPermissionsRole;
  // Retrieve all the existing roles. You can't apply filters on this query.
  roles(
    sort?: string,
    limit?: number,
    start?: number,
    where?: JSON,
  ): UsersPermissionsRole[];
  rolesConnection(
    sort?: string,
    limit?: number,
    start?: number,
    where?: JSON,
  ): UsersPermissionsRoleConnection;
  user(id: string): UsersPermissionsUser;
  users(
    sort?: string,
    limit?: number,
    start?: number,
    where?: JSON,
  ): UsersPermissionsUser[];
  usersConnection(
    sort?: string,
    limit?: number,
    start?: number,
    where?: JSON,
  ): UsersPermissionsUserConnection;
  me?: UsersPermissionsMe;
}
export interface Resource {
  id: string;
  created_at: Date;
  updated_at: Date;
  name?: string;
  author?: string;
  clase?: Class;
  description?: string;
  created_by?: AdminUser;
  updated_by?: AdminUser;
  files: UploadFile[];
}
export interface ResourceAggregator {
  count?: number;
  totalCount?: number;
}
export interface ResourceConnection {
  values?: Resource[];
  groupBy?: ResourceGroupBy;
  aggregate?: ResourceAggregator;
}
export interface ResourceConnectionAuthor {
  key?: string;
  connection?: ResourceConnection;
}
export interface ResourceConnectionClase {
  key?: string;
  connection?: ResourceConnection;
}
export interface ResourceConnectionCreated_at {
  key?: Date;
  connection?: ResourceConnection;
}
export interface ResourceConnectionCreated_by {
  key?: string;
  connection?: ResourceConnection;
}
export interface ResourceConnectionDescription {
  key?: string;
  connection?: ResourceConnection;
}
export interface ResourceConnectionId {
  key?: string;
  connection?: ResourceConnection;
}
export interface ResourceConnectionName {
  key?: string;
  connection?: ResourceConnection;
}
export interface ResourceConnectionUpdated_at {
  key?: Date;
  connection?: ResourceConnection;
}
export interface ResourceConnectionUpdated_by {
  key?: string;
  connection?: ResourceConnection;
}
export interface ResourceGroupBy {
  id?: ResourceConnectionId[];
  created_at?: ResourceConnectionCreated_at[];
  updated_at?: ResourceConnectionUpdated_at[];
  name?: ResourceConnectionName[];
  author?: ResourceConnectionAuthor[];
  clase?: ResourceConnectionClase[];
  description?: ResourceConnectionDescription[];
  created_by?: ResourceConnectionCreated_by[];
  updated_by?: ResourceConnectionUpdated_by[];
}
export interface ResourceInput {
  name?: string;
  author?: string;
  clase?: string;
  description?: string;
  files?: (string | number)[];
  created_by?: string;
  updated_by?: string;
}
export interface RoleInput {
  name: string;
  description?: string;
  type?: string;
  permissions?: (string | number)[];
  users?: (string | number)[];
  created_by?: string;
  updated_by?: string;
}
export interface Student {
  id: string;
  created_at: Date;
  updated_at: Date;
  name: string;
  lastname: string;
  municipality?: string;
  province?: string;
  neighborhood?: string;
  churchAddress?: string;
  studentAddress?: string;
  otherTitles?: JSON;
  secularJob?: string;
  email?: string;
  phone?: number;
  mobile?: number;
  ci: Long;
  church?: string;
  ministry?: string;
  califications?: JSON;
  active?: boolean;
  eventsActive?: JSON;
  devices?: JSON;
  username?: string;
  notes?: string;
  zone?: number;
  academyGrade?:number; // ENUM_STUDENT_ACADEMYGRADE;
  currentLevel?: ENUM_STUDENT_CURRENTLEVEL;
  levelFinished?: ENUM_STUDENT_LEVELFINISHED;
  aula?: Classroom;
  photo?: UploadFile;
  ciPhoto?: UploadFile;
  ciPhoto2?: UploadFile;
  created_by?: AdminUser;
  updated_by?: AdminUser;
  clase: Class[];
}
export interface StudentAggregator {
  count?: number;
  totalCount?: number;
  sum?: StudentAggregatorSum;
  avg?: StudentAggregatorAvg;
  min?: StudentAggregatorMin;
  max?: StudentAggregatorMax;
}
export interface StudentAggregatorAvg {
  phone?: number;
  mobile?: number;
  zone?: number;
  academyGrade?: number;
}
export interface StudentAggregatorMax {
  phone?: number;
  mobile?: number;
  zone?: number;
  academyGrade?: number;
}
export interface StudentAggregatorMin {
  phone?: number;
  mobile?: number;
  zone?: number;
  academyGrade?: number;
}
export interface StudentAggregatorSum {
  phone?: number;
  mobile?: number;
  zone?: number;
  academyGrade?: number;
}
export interface StudentConnection {
  values?: Student[];
  groupBy?: StudentGroupBy;
  aggregate?: StudentAggregator;
}
export interface StudentConnectionAcademyGrade {
  key?: number;
  connection?: StudentConnection;
}
export interface StudentConnectionActive {
  key?: boolean;
  connection?: StudentConnection;
}
export interface StudentConnectionAula {
  key?: string;
  connection?: StudentConnection;
}
export interface StudentConnectionCalifications {
  key?: JSON;
  connection?: StudentConnection;
}
export interface StudentConnectionChurch {
  key?: string;
  connection?: StudentConnection;
}
export interface StudentConnectionChurchAddress {
  key?: string;
  connection?: StudentConnection;
}
export interface StudentConnectionCi {
  key?: string;
  connection?: StudentConnection;
}
export interface StudentConnectionCiPhoto {
  key?: string;
  connection?: StudentConnection;
}
export interface StudentConnectionCreated_at {
  key?: Date;
  connection?: StudentConnection;
}
export interface StudentConnectionCreated_by {
  key?: string;
  connection?: StudentConnection;
}
export interface StudentConnectionCurrentLevel {
  key?: string;
  connection?: StudentConnection;
}
export interface StudentConnectionDevices {
  key?: JSON;
  connection?: StudentConnection;
}
export interface StudentConnectionEmail {
  key?: string;
  connection?: StudentConnection;
}
export interface StudentConnectionEventsActive {
  key?: JSON;
  connection?: StudentConnection;
}
export interface StudentConnectionId {
  key?: string;
  connection?: StudentConnection;
}
export interface StudentConnectionLastname {
  key?: string;
  connection?: StudentConnection;
}
export interface StudentConnectionLevelFinished {
  key?: string;
  connection?: StudentConnection;
}
export interface StudentConnectionMinistry {
  key?: string;
  connection?: StudentConnection;
}
export interface StudentConnectionMobile {
  key?: number;
  connection?: StudentConnection;
}
export interface StudentConnectionMunicipality {
  key?: string;
  connection?: StudentConnection;
}
export interface StudentConnectionName {
  key?: string;
  connection?: StudentConnection;
}
export interface StudentConnectionNeighborhood {
  key?: string;
  connection?: StudentConnection;
}
export interface StudentConnectionNotes {
  key?: string;
  connection?: StudentConnection;
}
export interface StudentConnectionOtherTitles {
  key?: JSON;
  connection?: StudentConnection;
}
export interface StudentConnectionPhone {
  key?: number;
  connection?: StudentConnection;
}
export interface StudentConnectionPhoto {
  key?: string;
  connection?: StudentConnection;
}
export interface StudentConnectionProvince {
  key?: string;
  connection?: StudentConnection;
}
export interface StudentConnectionSecularJob {
  key?: string;
  connection?: StudentConnection;
}
export interface StudentConnectionStudentAddress {
  key?: string;
  connection?: StudentConnection;
}
export interface StudentConnectionUpdated_at {
  key?: Date;
  connection?: StudentConnection;
}
export interface StudentConnectionUpdated_by {
  key?: string;
  connection?: StudentConnection;
}
export interface StudentConnectionUsername {
  key?: string;
  connection?: StudentConnection;
}
export interface StudentConnectionZone {
  key?: number;
  connection?: StudentConnection;
}
export interface StudentGroupBy {
  id?: StudentConnectionId[];
  created_at?: StudentConnectionCreated_at[];
  updated_at?: StudentConnectionUpdated_at[];
  name?: StudentConnectionName[];
  lastname?: StudentConnectionLastname[];
  municipality?: StudentConnectionMunicipality[];
  province?: StudentConnectionProvince[];
  neighborhood?: StudentConnectionNeighborhood[];
  churchAddress?: StudentConnectionChurchAddress[];
  studentAddress?: StudentConnectionStudentAddress[];
  otherTitles?: StudentConnectionOtherTitles[];
  secularJob?: StudentConnectionSecularJob[];
  email?: StudentConnectionEmail[];
  phone?: StudentConnectionPhone[];
  mobile?: StudentConnectionMobile[];
  ci?: StudentConnectionCi[];
  church?: StudentConnectionChurch[];
  ministry?: StudentConnectionMinistry[];
  califications?: StudentConnectionCalifications[];
  photo?: StudentConnectionPhoto[];
  active?: StudentConnectionActive[];
  eventsActive?: StudentConnectionEventsActive[];
  devices?: StudentConnectionDevices[];
  username?: StudentConnectionUsername[];
  notes?: StudentConnectionNotes[];
  zone?: StudentConnectionZone[];
  academyGrade?: StudentConnectionAcademyGrade[];
  currentLevel?: StudentConnectionCurrentLevel[];
  levelFinished?: StudentConnectionLevelFinished[];
  aula?: StudentConnectionAula[];
  ciPhoto?: StudentConnectionCiPhoto[];
  created_by?: StudentConnectionCreated_by[];
  updated_by?: StudentConnectionUpdated_by[];
}
export interface StudentInput {
  name: string;
  lastname: string;
  municipality?: string;
  province?: string;
  neighborhood?: string;
  churchAddress?: string;
  studentAddress?: string;
  otherTitles?: JSON;
  secularJob?: string;
  email?: string;
  phone?: number;
  mobile?: number;
  ci: Long;
  church?: string;
  ministry?: string;
  califications?: JSON;
  photo?: string;
  active?: boolean;
  eventsActive?: JSON;
  devices?: JSON;
  username?: string;
  clase?: (string | number)[];
  notes?: string;
  zone?: number;
  academyGrade?: number; //ENUM_STUDENT_ACADEMYGRADE;
  currentLevel?: ENUM_STUDENT_CURRENTLEVEL;
  levelFinished?: ENUM_STUDENT_LEVELFINISHED;
  aula?: string;
  ciPhoto?: string;
  ciPhoto2?: string;
  created_by?: string;
  updated_by?: string;
}
export interface Subject {
  id: string;
  created_at: Date;
  updated_at: Date;
  name?: string;
  notes?: string;
  departamento?: Department;
  obsoleta?: boolean;
  created_by?: AdminUser;
  updated_by?: AdminUser;
  clase: Class[];
}
export interface SubjectAggregator {
  count?: number;
  totalCount?: number;
}
export interface SubjectConnection {
  values?: Subject[];
  groupBy?: SubjectGroupBy;
  aggregate?: SubjectAggregator;
}
export interface SubjectConnectionCreated_at {
  key?: Date;
  connection?: SubjectConnection;
}
export interface SubjectConnectionCreated_by {
  key?: string;
  connection?: SubjectConnection;
}
export interface SubjectConnectionDepartamento {
  key?: string;
  connection?: SubjectConnection;
}
export interface SubjectConnectionId {
  key?: string;
  connection?: SubjectConnection;
}
export interface SubjectConnectionName {
  key?: string;
  connection?: SubjectConnection;
}
export interface SubjectConnectionNotes {
  key?: string;
  connection?: SubjectConnection;
}
export interface SubjectConnectionObsoleta {
  key?: boolean;
  connection?: SubjectConnection;
}
export interface SubjectConnectionUpdated_at {
  key?: Date;
  connection?: SubjectConnection;
}
export interface SubjectConnectionUpdated_by {
  key?: string;
  connection?: SubjectConnection;
}
export interface SubjectGroupBy {
  id?: SubjectConnectionId[];
  created_at?: SubjectConnectionCreated_at[];
  updated_at?: SubjectConnectionUpdated_at[];
  name?: SubjectConnectionName[];
  notes?: SubjectConnectionNotes[];
  departamento?: SubjectConnectionDepartamento[];
  obsoleta?: SubjectConnectionObsoleta[];
  created_by?: SubjectConnectionCreated_by[];
  updated_by?: SubjectConnectionUpdated_by[];
}
export interface SubjectInput {
  name?: string;
  notes?: string;
  clase?: (string | number)[];
  departamento?: string;
  obsoleta?: boolean;
  created_by?: string;
  updated_by?: string;
}
// A time string with format?: HH;?:mm;?:ss;.SSSexport type Time = any;
export interface updateClassInput {
  where?: InputID;
  data?: editClassInput;
}
export interface updateClassPayload {
  class?: Class;
}
export interface updateClassroomInput {
  where?: InputID;
  data?: editClassroomInput;
}
export interface updateClassroomPayload {
  classroom?: Classroom;
}
export interface updateDepartmentInput {
  where?: InputID;
  data?: editDepartmentInput;
}
export interface updateDepartmentPayload {
  department?: Department;
}
export interface updateProfesorInput {
  where?: InputID;
  data?: editProfesorInput;
}
export interface updateProfesorPayload {
  profesor?: Profesor;
}
export interface updateResourceInput {
  where?: InputID;
  data?: editResourceInput;
}
export interface updateResourcePayload {
  resource?: Resource;
}
export interface updateRoleInput {
  where?: InputID;
  data?: editRoleInput;
}
export interface updateRolePayload {
  role?: UsersPermissionsRole;
}
export interface updateStudentInput {
  where?: InputID;
  data?: editStudentInput;
}
export interface updateStudentPayload {
  student?: Student;
}
export interface updateSubjectInput {
  where?: InputID;
  data?: editSubjectInput;
}
export interface updateSubjectPayload {
  subject?: Subject;
}
export interface updateUserInput {
  where?: InputID;
  data?: editUserInput;
}
export interface updateUserPayload {
  user?: UsersPermissionsUser;
}
// The `Upload`export type export interface  represents a file upload. = any;
export type Upload = any;
export interface UploadFile {
  id: string;
  created_at: Date;
  updated_at: Date;
  name: string;
  alternativeText?: string;
  caption?: string;
  width?: number;
  height?: number;
  formats?: JSON;
  hash: string;
  ext?: string;
  mime: string;
  size: number;
  url: string;
  previewUrl?: string;
  provider: string;
  provider_metadata?: JSON;
  created_by?: AdminUser;
  updated_by?: AdminUser;
  related(sort?: String, limit?: number, start?: number, where?: JSON): Morph[];
}
export interface UploadFileAggregator {
  count?: number;
  totalCount?: number;
  sum?: UploadFileAggregatorSum;
  avg?: UploadFileAggregatorAvg;
  min?: UploadFileAggregatorMin;
  max?: UploadFileAggregatorMax;
}
export interface UploadFileAggregatorAvg {
  width?: number;
  height?: number;
  size?: number;
}
export interface UploadFileAggregatorMax {
  width?: number;
  height?: number;
  size?: number;
}
export interface UploadFileAggregatorMin {
  width?: number;
  height?: number;
  size?: number;
}
export interface UploadFileAggregatorSum {
  width?: number;
  height?: number;
  size?: number;
}
export interface UploadFileConnection {
  values?: UploadFile[];
  groupBy?: UploadFileGroupBy;
  aggregate?: UploadFileAggregator;
}
export interface UploadFileConnectionAlternativeText {
  key?: string;
  connection?: UploadFileConnection;
}
export interface UploadFileConnectionCaption {
  key?: string;
  connection?: UploadFileConnection;
}
export interface UploadFileConnectionCreated_at {
  key?: Date;
  connection?: UploadFileConnection;
}
export interface UploadFileConnectionCreated_by {
  key?: string;
  connection?: UploadFileConnection;
}
export interface UploadFileConnectionExt {
  key?: string;
  connection?: UploadFileConnection;
}
export interface UploadFileConnectionFormats {
  key?: JSON;
  connection?: UploadFileConnection;
}
export interface UploadFileConnectionHash {
  key?: string;
  connection?: UploadFileConnection;
}
export interface UploadFileConnectionHeight {
  key?: number;
  connection?: UploadFileConnection;
}
export interface UploadFileConnectionId {
  key?: string;
  connection?: UploadFileConnection;
}
export interface UploadFileConnectionMime {
  key?: string;
  connection?: UploadFileConnection;
}
export interface UploadFileConnectionName {
  key?: string;
  connection?: UploadFileConnection;
}
export interface UploadFileConnectionPreviewUrl {
  key?: string;
  connection?: UploadFileConnection;
}
export interface UploadFileConnectionProvider {
  key?: string;
  connection?: UploadFileConnection;
}
export interface UploadFileConnectionProvider_metadata {
  key?: JSON;
  connection?: UploadFileConnection;
}
export interface UploadFileConnectionSize {
  key?: number;
  connection?: UploadFileConnection;
}
export interface UploadFileConnectionUpdated_at {
  key?: Date;
  connection?: UploadFileConnection;
}
export interface UploadFileConnectionUpdated_by {
  key?: string;
  connection?: UploadFileConnection;
}
export interface UploadFileConnectionUrl {
  key?: string;
  connection?: UploadFileConnection;
}
export interface UploadFileConnectionWidth {
  key?: number;
  connection?: UploadFileConnection;
}
export interface UploadFileGroupBy {
  id?: UploadFileConnectionId[];
  created_at?: UploadFileConnectionCreated_at[];
  updated_at?: UploadFileConnectionUpdated_at[];
  name?: UploadFileConnectionName[];
  alternativeText?: UploadFileConnectionAlternativeText[];
  caption?: UploadFileConnectionCaption[];
  width?: UploadFileConnectionWidth[];
  height?: UploadFileConnectionHeight[];
  formats?: UploadFileConnectionFormats[];
  hash?: UploadFileConnectionHash[];
  ext?: UploadFileConnectionExt[];
  mime?: UploadFileConnectionMime[];
  size?: UploadFileConnectionSize[];
  url?: UploadFileConnectionUrl[];
  previewUrl?: UploadFileConnectionPreviewUrl[];
  provider?: UploadFileConnectionProvider[];
  provider_metadata?: UploadFileConnectionProvider_metadata[];
  created_by?: UploadFileConnectionCreated_by[];
  updated_by?: UploadFileConnectionUpdated_by[];
}
export interface UserInput {
  username: string;
  email: string;
  provider?: string;
  password?: string;
  resetPasswordToken?: string;
  confirmed?: boolean;
  blocked?: boolean;
  role?: string;
  created_by?: string;
  updated_by?: string;
}
export interface UserPermissionsPasswordPayload {
  ok: boolean;
}
export interface UsersPermissionsLoginInput {
  identifier: string;
  password: string;
  provider?: string;
}
export interface UsersPermissionsLoginPayload {
  jwt?: string;
  user: UsersPermissionsMe;
}
export interface UsersPermissionsMe {
  id: string;
  username: string;
  email: string;
  confirmed?: boolean;
  blocked?: boolean;
  role?: UsersPermissionsMeRole;
}
export interface UsersPermissionsMeRole {
  id: string;
  name: string;
  description?: string;
  type?: string;
}
export interface UsersPermissionsPermission {
  id: string;
  type: string;
  controller: string;
  action: string;
  enabled: boolean;
  policy?: string;
  role?: UsersPermissionsRole;
  created_by?: AdminUser;
  updated_by?: AdminUser;
}
export interface UsersPermissionsRegisterInput {
  username: string;
  email: string;
  password: string;
}
export interface UsersPermissionsRole {
  id: string;
  name: string;
  description?: string;
  type?: string;
  created_by?: AdminUser;
  updated_by?: AdminUser;
  permissions(
    sort?: string,
    limit?: number,
    start?: number,
    where?: JSON,
  ): UsersPermissionsPermission[];
  users(
    sort?: string,
    limit?: number,
    start?: number,
    where?: JSON,
  ): UsersPermissionsUser[];
}
export interface UsersPermissionsRoleAggregator {
  count?: number;
  totalCount?: number;
}
export interface UsersPermissionsRoleConnection {
  values?: UsersPermissionsRole[];
  groupBy?: UsersPermissionsRoleGroupBy;
  aggregate?: UsersPermissionsRoleAggregator;
}
export interface UsersPermissionsRoleConnectionCreated_by {
  key?: string;
  connection?: UsersPermissionsRoleConnection;
}
export interface UsersPermissionsRoleConnectionDescription {
  key?: string;
  connection?: UsersPermissionsRoleConnection;
}
export interface UsersPermissionsRoleConnectionId {
  key?: string;
  connection?: UsersPermissionsRoleConnection;
}
export interface UsersPermissionsRoleConnectionName {
  key?: string;
  connection?: UsersPermissionsRoleConnection;
}
export interface UsersPermissionsRoleConnectionType {
  key?: string;
  connection?: UsersPermissionsRoleConnection;
}
export interface UsersPermissionsRoleConnectionUpdated_by {
  key?: string;
  connection?: UsersPermissionsRoleConnection;
}
export interface UsersPermissionsRoleGroupBy {
  id?: UsersPermissionsRoleConnectionId[];
  name?: UsersPermissionsRoleConnectionName[];
  description?: UsersPermissionsRoleConnectionDescription[];
  type?: UsersPermissionsRoleConnectionType[];
  created_by?: UsersPermissionsRoleConnectionCreated_by[];
  updated_by?: UsersPermissionsRoleConnectionUpdated_by[];
}
export interface UsersPermissionsUser {
  id: string;
  created_at: Date;
  updated_at: Date;
  username: string;
  email: string;
  provider?: string;
  confirmed?: boolean;
  blocked?: boolean;
  role?: UsersPermissionsRole;
  created_by?: AdminUser;
  updated_by?: AdminUser;
}
export interface UsersPermissionsUserAggregator {
  count?: number;
  totalCount?: number;
}
export interface UsersPermissionsUserConnection {
  values?: UsersPermissionsUser[];
  groupBy?: UsersPermissionsUserGroupBy;
  aggregate?: UsersPermissionsUserAggregator;
}
export interface UsersPermissionsUserConnectionBlocked {
  key?: boolean;
  connection?: UsersPermissionsUserConnection;
}
export interface UsersPermissionsUserConnectionConfirmed {
  key?: boolean;
  connection?: UsersPermissionsUserConnection;
}
export interface UsersPermissionsUserConnectionCreated_at {
  key?: Date;
  connection?: UsersPermissionsUserConnection;
}
export interface UsersPermissionsUserConnectionCreated_by {
  key?: string;
  connection?: UsersPermissionsUserConnection;
}
export interface UsersPermissionsUserConnectionEmail {
  key?: string;
  connection?: UsersPermissionsUserConnection;
}
export interface UsersPermissionsUserConnectionId {
  key?: string;
  connection?: UsersPermissionsUserConnection;
}
export interface UsersPermissionsUserConnectionProvider {
  key?: string;
  connection?: UsersPermissionsUserConnection;
}
export interface UsersPermissionsUserConnectionRole {
  key?: string;
  connection?: UsersPermissionsUserConnection;
}
export interface UsersPermissionsUserConnectionUpdated_at {
  key?: Date;
  connection?: UsersPermissionsUserConnection;
}
export interface UsersPermissionsUserConnectionUpdated_by {
  key?: string;
  connection?: UsersPermissionsUserConnection;
}
export interface UsersPermissionsUserConnectionUsername {
  key?: string;
  connection?: UsersPermissionsUserConnection;
}
export interface UsersPermissionsUserGroupBy {
  id?: UsersPermissionsUserConnectionId[];
  created_at?: UsersPermissionsUserConnectionCreated_at[];
  updated_at?: UsersPermissionsUserConnectionUpdated_at[];
  username?: UsersPermissionsUserConnectionUsername[];
  email?: UsersPermissionsUserConnectionEmail[];
  provider?: UsersPermissionsUserConnectionProvider[];
  confirmed?: UsersPermissionsUserConnectionConfirmed[];
  blocked?: UsersPermissionsUserConnectionBlocked[];
  role?: UsersPermissionsUserConnectionRole[];
  created_by?: UsersPermissionsUserConnectionCreated_by[];
  updated_by?: UsersPermissionsUserConnectionUpdated_by[];
}

export interface GQLError {
  graphQLErrors: GraphQLError
}