import { gql } from '@apollo/client';
import { FRAGMENT_BASIC_PROFESOR } from '../fragments/profesor';
import { FRAGMENT_STUDENT } from '../fragments/students';

export const ROLES_FRAGMENT = gql`
fragment rolesFragment on UsersPermissionsRole{
    id
    name
    description
    type
    created_by
    updated_by
}`;

export const ROLES = gql`
query GetRoles($start:Int, $limit: Int, $sort: String, $where: JSON){
    roles(sort: $sort, limit: $limit, start: $start, where: $where){
        id
        name
    }    
}`;

export const GET_USER = gql`
query GetUser($where: JSON){
  users(where: $where){    
      id          
  }
}
`
//Trasladar esto a la parte de las mutaciones de los usuarios
export const MUTATION_CREATE_USER = gql`
mutation CreateUser($input: createUserInput) {
    createUser(input: $input) {
      user {
        id
        role {
          id
          name
        }
        username
      }
    }
  }
`

export const MUTATION_DELETE_USER = gql`
mutation DeleteUser($input: deleteUserInput){
  deleteUser(input: $input){
    user {
      id
    }
  }
}
`


export const EXISTS_USER = gql`
query ExistsUsers($limit: Int, $sort: String, $start: Int, $where: JSON) @client {
  studentsConnection(limit: $limit, sort: $sort, start: $start, where: $where) {
    values {
      ...studentFragment
      clase {
        profesor {
          id
        }
      }
    }
    aggregate {
      count
    }
  }
  profesorsConnection (limit:$limit, start:$start, sort: $sort, where:$where) {
    values {
      ...basicProfesorFragment
    }
    aggregate {
      count
    }
  }
}
${FRAGMENT_BASIC_PROFESOR}
${FRAGMENT_STUDENT}`;

